/*!
  PAGE REGATA
  ===============================================
  */
.page-regata
  .hero-video
    position: relative

    img, .img
      position: relative
      z-index: 1

    &:after
      pseudo()
      width: 100%
      height: 100%
      bottom: -170px
      right: 0
      // background-color: #ccc
      bg(bottom right,contain)
      background-image: url('../images/page-regata-pattern.jpg')


  .section-desc
    margin-bottom: 85px

  .section-quote
    margin: -130px 0 0
    padding: 140px 0 30px
    @media pad
      padding-top: 80px
      padding-bottom: 80px
    @media phone
      margin-top: -115px
      padding-top: 120px
      padding-bottom: 100px

  .section-map
    margin-top: -80px

  .section-yacht-spec
    margin-top: -32px
    @media pad
      margin-top: 0
