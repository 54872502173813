/*!
 * --- SECTION NEXT -----------------------------
 */

.section-next
  text-align: center
  margin-bottom: 22px

  &:not(:first-child)
    margin-top: 120px
    @media phone
      margin-top: 60px

  &__type
    color: rgba(colorText,0.5)
    font-size: 14px

  &__page
    margin: 30px 0 40px
    transit()
    @media phone
      margin-bottom: 30px

  &__icon
    display: flex
    align-items: center
    justify-content: center
    size(56px,56px)
    margin: 0 auto
    @media phone
      size(46px,46px)

    .icon
      size(100%,100%)
      fill: colorSand
      transit()

  &__link
    display: block

    &:hover .icon
      fill: colorBlue

  &__link:hover &__page
    color: colorBlue
