/*!
  SECTION HERO
  ===============================================
  */

#bubbles
  position: relative
  width: 100%
  height: 100%


.section-hero
  pointer-events: auto
  height: 100vh
  min-height: 480px
  max-height: 1080px
  display: flex
  flex-direction: column
  justify-content: flex-end
  background-color: colorText
  position: relative
  overflow: hidden
  @media wide
    max-height: 1280px
  @media phone
    max-height: 670px

  &__bg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1

    &-pic
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 2
      bg(center,cover)

      &.is-mob
        display: none
        @media phone
          display: block

      &.is-desk
        @media phone
          display: none

    img
      width: 100%
      height: 100%
      object-fit: cover
      @media phone
        object-fit: none
        position: absolute
        bottom: 0
        height: 100%
        width: auto

  .canvas-container
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2
    color: lightness(colorText,35%)


.hero
  width: 100%
  color: #fff
  font-size: 14px
  position: relative

  &__top
    @media phone
      padding-bottom: 30px

    &-body
      // max-width: 440px
      max-width: 460px
      position: relative
      z-index: 2
      @media phone
        max-width: 335px

  &__timer
    margin-bottom: 20px
    @media phone
      margin-bottom: 8px

  &__title
    font-family: fontPlayfair
    text(64,74)
    margin-bottom: 20px
    @media phone
      text(36,44)
      margin-bottom: 12px

  &__info
    @media phone
      font-size: 14px

  &__bottom
    padding: 40px 0 30px
    position: relative
    z-index: 2
    @media phone
      display: none

  &__button
    position: absolute
    right: 0
    bottom: 0
    z-index: 2
    @media pad
      position: relative


.hero-button
  width: 600px
  height: 100px
  display: flex
  align-items: center
  justify-content: center
  position: relative
  font-size: 16px
  @media pad
    height: 65px
    margin-left: auto
  @media phone
    width: 100%
    padding-left: 32px

  .icon
    fill: colorText
    height: 100%
    position: absolute
    top: 0
    left: 0
    width: 200%
    transit()
    @media phone
      left: -20px

  &__text
    position: relative
    z-index: 1

  &:hover
    .icon
      fill: colorSand

  &__pin
    display: block
    size(206px,12px)
    position: absolute
    top: 50%
    left: -150px
    z-index: 1
    margin-top: -6px
    @media phone
      size(140px,10px)
      margin-top: -5px
      left: -80px

    &:before,
    &:after
      pseudo()

    &:before
      size(12px,12px)
      border-radius: 50%
      border: 1px solid #fff
      right: 0
      top: 50%
      margin-top: -6px
      z-index: 5
      @media phone
        size(10px,10px)

    &:after
      height: 1px
      left: 0
      right: 12px
      top: 6px
      background-color: rgba(#fff,0.2)
      @media phone
        right: 10px
        top: 4px

    span
      display: none
      position: absolute
      size(40px,40px)
      top: 50%
      right: -14px
      margin-top: -20px
      border-radius: 50%
      background: colorBlue
      animation: hero-button-pin-pulse 3s
      animation-iteration-count: infinite
      animation-delay: 1s
      opacity: 0
      z-index: 3
      @media phone
        right: -15px
        margin-top: -21px

      &:before,
      &:after
        pseudo()
        border-radius: 50%
        background: #fff
        animation-iteration-count: infinite
        opacity: 0

      &:before
        size(60px,60px)
        top: 50%
        left: 50%
        margin-top: -30px
        margin-left: -30px
        animation: hero-button-pin-pulse 2.5s
        animation-delay: 1.5s
        opacity: 0.2
        z-index: 2

      &:after
        size(80px,80px)
        top: 50%
        left: 50%
        margin-top: -40px
        margin-left: -40px
        animation: hero-button-pin-pulse 3s
        animation-delay: 1s
        opacity: 0.2
        z-index: 1

  &--current &__pin
    &:before
      background: colorBlue
      border-color: rgba(#fff,0)

    span
      display: block


@keyframes hero-button-pin-pulse
  from
    transform: scale(0)
    opacity: 0

  15%
    opacity: .2

  85%
    opacity: .2

  to
    transform: scale(1)
    opacity: 0


.timer-tick
  display: inline-flex

  &__group
    display: inline-flex

    &:not(:last-child)
      margin-right: 0.3em

  &__type
    margin-left: 0.3em
