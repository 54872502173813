/*!
  SECTION PARTNERS
  ===============================================
  */
.partners-grid
  align-items: center
  justify-content: center

  &__item
    cl(1,3)
    overflow: hidden

  &__picture
    padding: 5px 0
    position: relative
    display: flex
    align-items: center
    justify-content: center
    transition: transform 1s ease-in-out, opacity 0.2s ease-in-out 0.5s

    .img
      margin: 0 auto

    &.is-two
      position: absolute
      top: 0
      height: 100%
      left: 0
      width: 100%
      transform: translateY(100%)
      opacity: 0

  &__item.is-switch &__picture
    &.is-one
      transform: translateY(100%)
      opacity: 0

    &.is-two
      transform: translateY(0)
      opacity: 1
