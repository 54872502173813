/*!
  SECTION INSTAGRAM
  ===============================================
  */
.section-instagram
  height: 640px
  overflow: hidden
  position: relative
  @media wide
    height: 100vh
    max-height: 1280px
  @media phone
    height: auto
    padding-top: 34px

  &__left
    position: absolute
    top: 48%
    left: 50%
    transform: translate(-50%,-50%)
    width: 100%
    max-width: 1140px
    padding: 0 20px
    z-index: 1
    @media phone
      position: relative
      transform: none
      top: auto
      left: auto

  &__right
    position: absolute
    top: 0
    right: 0
    height: 100%
    width: 100%
    // max-width: 1200px
    // transform: translateX(-50%)
    overflow: hidden
    // @media laptop
    //   max-width: 100%
    @media phone
      position: relative
      left: auto
      top: auto
      transform: none

    .section-instagram-pictures
      margin-left: auto
      // margin-top: -18.7%
      position: relative
      // left: 25px
      @media pad
        left: 0
      @media phone
        left: auto
        position: relative
        margin-left: 0
        margin-top: 0


.section-instagram-content
  max-width: 500px
  @media pad
    max-width: 280px
  @media phone
    max-width: 100%
    text-align: center
    margin-bottom: 50px

  &__icon
    margin-bottom: 20px
    @media phone
      margin-bottom: 15px

    .icon
      fill: colorSand
      size(58px,58px)
      @media phone
        margin: 0 auto

  &__title
    margin-bottom: 20px
    @media phone
      margin-bottom: 13px

  &__txt
    text(14,24)
    max-width: 400px
    @media phone
      line-height: (22/14)

    a
      color: colorBlue
      &:hover
        opacity: 0.5

  &__bottom
    margin-top: 30px
    @media phone
      margin-top: 20px


.section-instagram-pictures
  height: 100%
  overflow: hidden
  max-width: 50%
  @media phone
    max-width: 100%

  &__rails
    display: flex
    @media phone
      display: block


  &__rail
    width: 50%
    position: relative
    transit()
    @media phone
      display: flex
      flex-wrap: nowrap
      width: auto

    &:first-child
      padding-right: 12px
      @media phone
        padding-right: 20px
        padding-bottom: 20px

    &:last-child
      padding-left: 12px
      top: -70px
      @media phone
        top: 0
        left: -40px
        padding-left: 0
        padding-right: 20px

  &__item
    display: block

    @media phone
      padding: 0
      flex: none
      padding-right: 20px

    &:not(:last-child)
      padding-bottom: 24px
      @media phone
        padding-bottom: 0

  &__pic
    object-fit: cover
    size(100%,100%)
    box-shadow: 5px 5px 10px rgba(216, 216, 216, 0.5)
    @media phone
      size(150px,210px)
