/*!
  SECTION NEWS
  ===============================================
  */
.section-news
  background-color: colorText
  bg(center,cover)
  background-image: url('../images/bg-pattern-news.jpg')
  color: #fff
  padding: 80px 0 120px
  @media phone
    padding: 45px 0 50px

  &__top
    margin-bottom: 70px
    @media phone
      margin-bottom: 30px

  &--news-single
    padding-bottom: 50px


.news-slider
  position: relative

  &__left
    width: 100%
    max-width: 1140px
    padding: 0 20px
    position: absolute
    top: -12px
    bottom: 0
    left: 50%
    transform: translateX(-50%)
    display: flex
    flex-direction: column
    justify-content: space-between
    @media phone
      display: none

  &__right
    margin-left: 25%
    position: relative
    z-index: 1
    @media phone
      margin-left: 20px

  .swiper-slide
    max-width: (300px + 50)
    padding-right: 50px
    @media wide
      max-width: (350px * 1.8)
    @media phone
      padding-right: 20px
      max-width: (260px + 20)


/* --- NEWS CARD ---------------------- */
.news-card
  background-color: #fff
  color: colorText
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  position: relative
  overflow: hidden

  &:after
    pseudo()
    size(50px,50px)
    transform: rotate(45deg)
    bottom: -25px
    right: -25px
    background-color: colorSand
    transit()

  &__picture
    height: 255px
    overflow: hidden
    @media wide
      height: (255px * 1.8)
    @media phone
      height: 220px

    .picture
      height: 100%
      transit()

    img
      height: 100%
      object-fit: cover

  &__body
    padding: 30px 30px 0
    display: flex
    flex-direction: column
    justify-content: space-between
    flex-grow: 1
    @media phone
      padding: 20px 20px 0

  &__title
    font-family: fontPlayfair
    text(27,32)
    trunc(3)
    min-height: 96px
    max-height: 96px

  &__bottom
    padding: 35px 0
    position: relative
    @media phone
      padding: 25px 0 30px

  &__date
    color: rgba(colorText,0.5)
    font-size: 12px

  &__button
    position: absolute
    bottom: 20px
    right: 38px
    font-size: 12px
    color: colorSand
    opacity: 0
    transit()

  &:hover
    &:after
      size(80px,80px)
      bottom: -40px
      right: -40px

  &:hover &__picture .picture
    transform: scale(1.05)
