/*!
  SECTION FAQ
  ===============================================
  */
.section-faq
  & > .container
    @media wide
      max-width: containerWidth


.faq
  border-top: 1px solid #E2E2E2

  &__item
    border-bottom: 1px solid #E2E2E2
    padding: 30px 0
    @media phone
      padding: 15px 0

  &__q
    display: flex
    align-items: center
    justify-content: space-between
    cursor: pointer

  &__title
    font-family: fontPlayfair
    text(27,35)
    padding-right: 15px
    @media phone
      font-size: 18px

  &__icon
    flex: none
    // size(40px,40px)
    // display: flex
    // align-items: center
    // justify-content: center
    border: 1px solid rgba(colorText,0.2)
    transit()

    .icon
      fill: colorText
      size(14px,14px)

  // &__q:hover &__icon
  //   border-color: colorText

  &__a
    max-width: 90%
    text(14,24)
    color: rgba(colorText,0.5)
    padding-top: 12px
    @media tablet
      max-width: 740px

  &__q.is-active &__icon
    border-color: colorSand
    background-color: colorSand

    .icon
      fill: #fff
      transform: rotateX(180deg)
