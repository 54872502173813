/*!
  SECTION EVENTS
  ===============================================
  */
.section-events
  padding: 80px 0 0
  @media phone
    padding: 45px 0 20px


.events-slider
  padding: 90px 0
  position: relative
  color: #fff
  @media phone
    padding: 0

  &:before
    pseudo()
    width: 37%
    height: 100%
    top: 0
    left: 0
    background-color: colorText
    @media phone
      display: none


.eventCard
  display: block
  height: 420px
  padding: 27px 10px 32px 30px
  color: #fff
  background-color: colorText
  position: relative
  overflow: hidden
  @media wide
    height: (420px * 1.8)
  @media phone
    height: 400px

  &__picture
    height: 100%
    width: 100%
    position: absolute
    top: 0
    left: 0
    transit()

    img
      height: 100%
      object-fit: cover

  &__body
    position: relative
    z-index: 1
    height: 100%

  &__date
    font-size: 12px
    margin-bottom: 10px

  &__title
    font-family: fontPlayfair
    font-size: 27px

  &__label
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    text-align: center
    padding: 10px
    background-color: colorSand
    color: #fff
    z-index: 2
    transform: translateY(100%)
    font-size: 0.8em
    transition: all 0.32s ease-in-out

  &:hover &__picture
    transform: scale(1.05)

  &:hover &__label
    transform: translateY(0)
