/*!
  BLOCK MENU
  ===============================================
  */
.header-menu
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  max-height: 100%
  overflow: hidden
  z-index: 10
  background-color: rgba(#0B1125,0.98)
  bg(center,cover)
  background-image: url('../images/menu-bg.jpg')
  color: #fff
  padding-top: 60px

  &:after
    pseudo()
    size(100%,100%)
    top: 0
    left: 0
    background-color: rgba(#0B1125,0.96)
    z-index: 0

  &__body
    height: 100%
    flex-grow: 1
    overflow-y: auto
    overflow-x: hidden
    display: flex
    flex-direction: column
    justify-content: space-between
    position: relative
    z-index 1
    @media pad
      padding: 0 20px

  &__top
    flex-grow: 1
    width: 100%
    max-width: 800px
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0 0 25px

  &__contacts
    text(14,24)
    max-width: 800px
    margin: 0 auto
    @media pad
      margin-bottom: 36px
    @media phone
      display: flex
      align-items: center
      justify-content: space-between

    &-socials
      display: none
      @media phone
        display: block

  &__bottom
    position: relative
    padding: 40px 0 30px
    @media pad
      padding: 0

  &__button
    position: absolute
    bottom: 0
    right: 0
    @media pad
      position: relative
      bottom: auto
      right: auto


.main-menu
  font-family: fontPlayfair
  display: inline-block
  text(64,104)
  column-count: 2
  position: relative
  overflow: hidden
  @media pad
    text(42,80)
  @media phone
    text(30,50)
    column-count: 1

  &__item
    &--socials
      @media phone
        display: none

  &__link
    &:hover
      color: colorBlue

  &__item.is-subs > &__link
    position: relative
    padding-right: 30px

    &:after
      pseudo()
      size(8px,12px)
      bg(center,contain)
      background-image: url('../icons/arrow-menu-sub.svg')
      top: 62%
      right: 0
      margin-top: -6px

  &__back-link
    font-size 14px
    position: relative
    padding-left: 18px
    font-family: fontNoto

    &:before
      pseudo()
      size(8px,12px)
      bg(center,contain)
      background-image: url('../icons/arrow-menu-sub.svg')
      transform: rotate(180deg)
      top: 50%
      margin-top: -6px
      left: 0

  &__subs
    position: absolute
    top: 0
    left: 100%
    width: 100%
    bottom: 0
    z-index: 2
    display: flex
    flex-direction: column
    align-items: flex-start
    visibility: hidden
    // margin-top: 52px
    transit()
    @media phone
      margin-top: 16px

  &__sub
    flex-grow: 1
    max-height: 100%
    width: 100%
    margin-top: 25px
    overflow: hidden
    position: relative
    @media phone
      margin-top: 15px

  &__sub-list
    column-count: 2
    column-fill: auto
    display: inline-block
    @media phone
      column-count: 1

  &__sub-list &__item
    font-size: 27px
    line-height: (44/27)
    @media phone
      font-size: 18px

  &.is-subs-active &__item
    visibility: hidden

  &__item.is-active &__subs
    left: 0
    visibility: visible

  &__item.is-active > &__link
    visibility: hidden

  &.is-subs-active &__item.is-active
    visibility: visible

  &.is-subs-active &__item.is-active &__item
    visibility: visible
