/*!
  SECTION QUOTE
  ===============================================
  */
.section-quote
  background-color: colorText
  color: #fff
  margin-bottom: 60px
  margin-top: -70px
  @media phone
    margin-top: 0

  &__body
    max-width: 700px
    margin: 0 auto
    text-align: center
    padding: 110px 0 160px
    @media pad
      padding-bottom: 80px
    @media phone
      padding: 50px 0

  &__icon
    margin-bottom: 40px

    .icon
      size(35px,24px)
      fill: colorSand
      margin: 0 auto

  &__txt
    text(27,36)
    font-family: fontPlayfair
    margin-bottom: 45px
    @media phone
      font-size: 20px

  &__picture
    size(60px,60px)
    border-radius: 100%
    overflow: hidden
    margin: 0 auto 20px

    img
      height: 100%
      object-fit: cover

  &__name
    font-size: 14px

  &__type
    padding-top: 10px
    font-size: 12px
    color: rgba(#fff,0.5)

  .slider-dots
    position: absolute
    z-index: 2
    // bottom: (160px / 2)
    bottom: 113px
    left: 0
    width: 100%
    @media pad
      bottom: (80px / 2)
    @media phone
      bottom: ((50px / 2) / 2)
