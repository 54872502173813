/*!
  FOOTER
  ===============================================
  */
.footer
  background-color: colorText
  color: rgba(#fff,0.3)
  text-align: center
  font-size: 14px
  padding: 60px 0 65px
  @media pad
    padding: 45px 0 42px

  &:after
    pseudo()
    bottom: 0
    left: 0
    z-index: 0
    size(100%,246px)
    bg(bottom center,cover)
    background-image: url('../images/bg-pattern-footer.png')

  &__title
    position: relative
    z-index: 1
    margin-bottom: 28px
    @media pad
      margin-bottom: 16px

  &__form
    position: relative
    z-index: 1
    margin-bottom: 50px
    @media pad
      margin-bottom: 34px

  &__socials
    position: relative
    z-index: 1
    margin-bottom: 40px
    @media pad
      margin-bottom: 20px

  &__copyright
    position: relative
    z-index: 1

  .link
    &:hover
      opacity: 1
      color: #fff

.f-form
  position: relative

  &.is-process
    &:before
      background-color: colorText
    &:after
      border-color: #fff rgba(#fff,0) #fff rgba(#fff,0)

  &__group
    max-width: 300px
    margin: 0 auto
    position: relative

  &__input
    height: (40px + (15 * 2))
    border-bottom: 1px solid rgba(#fff,0.2)
    color: #fff
    font-size: 14px
    padding-right: 50px

    &.error
      border-color: #ff6b6b

  &__button
    size(40px,40px)
    border: 1px solid rgba(#fff,0.2)
    position: absolute
    right: 0
    top: 50%
    margin-top: -20px

    // &:hover
    //   border-color: rgba(#fff,1)

    .icon
      size(14px,14px)
      margin: auto
      fill: #fff

  &__bottom
    font-size: 12px
    padding-top: 28px
    @media pad
      padding-top: 22px

  &__error
    position: absolute
    top: 0
    bottom: 1px
    left: 0
    display: flex
    align-items: center
    justify-content: center
    color: #fff
    background-color: colorText
    opacity: 0
    visibility: hidden
    z-index: 1

  &__success
    position: absolute
    top: -5px
    left: 0
    bottom: -5px
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    color: #fff
    background-color: colorText
    opacity: 0
    visibility: hidden
    z-index: 1

  &.is-sent &__success
    opacity: 1
    visibility: visible

  &.is-fail &__error
    opacity: 1
    visibility: visible


.f-copyright
  max-width: 514px
  margin: 0 auto
  line-height: (24/14)

  &__item
    display: flex
    align-items: center
    justify-content: space-between
    @media phone
      justify-content: center

    &:not(:last-child)
      margin-bottom: 20px

    &--one
      @media phone
        display: none

  &__tel,
  &__email
    @media phone
      display: none


.cookie-agree
  position: fixed
  bottom: 20px
  left: 50px
  right: 50px
  color: #fff
  display: none
  @media pad
    left: 5px
    right: 5px
  @media phone
    bottom: 0
    left: 0
    right: 0

  &.is-active
    display: block
    z-index: 100

  &__body
    display: flex
    align-items: center
    justify-content: space-between
    background-color: colorSand
    width: 100%
    max-width: 1100px
    margin: 0 auto
    font-size: 14px
    line-height: (20/14)
    padding: 5px
    @media phone
      flex-direction: column
      font-size: 12px
      padding: 15px 5px

  &__txt
    padding: 0 35px
    flex-grow: 1
    @media pad
      padding: 0 15px
    @media phone
      margin-bottom: 15px

  &__control
    flex: none
    width: 100%
    max-width: 200px

  &__btn
    background-color: #fff
    color: colorText
    height: 54px
    @media phone
      height: 34px

    &:hover
      background-color: colorText
      color: #fff
