//- FONT
font = sans-serif
fontPlayfair = 'Playfair Display', serif
fontNoto = 'Noto Sans', sans-serif

//- COLORS
color = #000
colorText = #111C3E
colorTextLight = rgba(colorText,0.5)
colorSand = #DEB992
colorBlue = #1D8EFF

containerWidth = (1100px + 40)

//- GRID
gridPadding = 15px

//- BREAKPOINTS
wide = 'all and (min-width: 1760px)'
note = 'all and (max-width: 1759px)'
laptop = 'all and (max-width: 1399px)'
tablet = 'all and (max-width: 1279px)'
pad = 'all and (max-width: 1023px)'
phone = 'all and (max-width: 767px)'

/*!
  BREAKPOINTS

  iPad Pro - 1366, 1024
  macbook old = 1280
  iPad - 1024, 768
  iPhone - 414, 375, 320

  note = 'all and (max-width: 1759px)'
  laptop = 'all and (max-width: 1399px)'
  tablet = 'all and (max-width: 1279px)'
  pad = 'all and (max-width: 1023px)'
  phone = 'all and (max-width: 767px)'
*/

/*!
  FONTS
  ===============================================
  */
@font-face
  font-family: 'Noto Sans'
  src: local('Noto Sans'), local('NotoSans'),
  url('../fonts/notosans.woff2') format('woff2'),
  url('../fonts/notosans.woff') format('woff')
  font-weight: 400
  font-style: normal

@font-face
    font-family: 'Playfair Display'
    src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'),
    url('../fonts/Playfairdisplayregular.woff2') format('woff2'),
    url('../fonts/Playfairdisplayregular.woff') format('woff')
    font-weight: 400
    font-style: normal
