/*!
  SECTION TABS
  ===============================================
  */
.section-tabs
  position: relative
  z-index: 1

  & > .container
    @media wide
      max-width: containerWidth

  .section-title
    margin-bottom: 40px

  .tab-ui
    padding-top: 40px
    @media phone
      padding-top: 20px

    &__txt
      max-width: 550px
      padding-bottom: 130px
      line-height: 1.75
      @media pad
        padding-bottom: 50px

    // &__txt
    //   @media phone
    //     padding-bottom: 45px

    &__cols--middle .tab-ui__txt
      padding-top: 35px
      @media phone
        padding-top: 0


.tabs-box
  display: flex
  align-items: center
  padding-right: 50px
  padding-top: 40px
  @media tablet
    padding-right: 0
  @media phone
    padding-top: 20px
    flex-direction: column-reverse

  &__left
    flex-grow: 1
    padding-right: 50px
    @media pad
      padding-right: 30px
    @media phone
      padding-right: 0

  &__right
    flex: none
    max-width: 36%
    @media phone
      max-width: 50%

  &__pictures
    position: relative
    overflow: hidden

  &__picture
    width: 100%
    position: relative

    &.is-active
      z-index: 10

    &.is-current
      z-index: 9

    img
      size(100%,100%)
      object-fit: cover

    &:not(:first-child)
      position: absolute
      top: 0
      left: 0
      size(100%,100%)
