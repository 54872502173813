/*
  BREAKPOINTS

  iPad Pro - 1366, 1024
  macbook old = 1280
  iPad - 1024, 768
  iPhone - 414, 375, 320

  note = 'all and (max-width: 1759px)'
  laptop = 'all and (max-width: 1399px)'
  tablet = 'all and (max-width: 1279px)'
  pad = 'all and (max-width: 1023px)'
  phone = 'all and (max-width: 767px)'
*/
/*
  FONTS
  ===============================================
  */
@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans'), local('NotoSans'), url("../fonts/notosans.woff2") format('woff2'), url("../fonts/notosans.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Playfair Display';
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url("../fonts/Playfairdisplayregular.woff2") format('woff2'), url("../fonts/Playfairdisplayregular.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}
/*
  BASE
  ===============================================
  */
body,
html {
  border: 0;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
html {
  font-size: 16px;
  cursor: none !important;
}
html.is-no-scroll {
  overflow: hidden;
}
html * {
  cursor: none !important;
}
html *:hover {
  cursor: none !important;
}
body {
  font-family: 'Noto Sans', sans-serif;
  color: #111c3e;
  font-weight: normal;
  background-color: #fff;
  line-height: 1;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  position: relative;
}
body.is-disabled-scroll {
  overflow: hidden;
  max-height: 100vh;
}
.browsehappy {
  display: block;
  margin: 0;
  padding: 30px;
  text-align: center;
  background: #e55454;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
}
.browsehappy a,
.browsehappy a:hover {
  color: #fff;
  text-decoration: underline;
}
.container {
  width: 100%;
  max-width: 1140px;
  padding: 0 20px;
  margin: 0 auto;
}
@media all and (min-width: 1760px) {
  .container {
    max-width: 1710px;
  }
}
/*
  FOOTER TO BOTTOM
  ===============================================
  */
.wrapper {
  flex: 1 1 auto;
  width: 100%;
  min-height: 1vh;
  display: flex;
  flex-flow: column nowrap;
}
.main {
  flex: 1 1 auto;
  min-height: 1vh;
}
.header,
.footer {
  flex-shrink: 0;
}
/*
  UI ELEMENTS
  ===============================================
  */
a {
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: all 0.25s linear;
}
strong,
b {
  font-weight: bold;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  width: auto;
  outline: none;
  line-height: 1;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: rgba(255,255,255,0);
  transition: all 0.25s linear;
}
button::placeholder,
input::placeholder,
select::placeholder,
textarea::placeholder {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
}
button {
  cursor: none !important;
}
button:hover {
  cursor: none !important;
}
button,
button[type="submit"],
input[type="submit"] {
  cursor: pointer;
}
input[type="search"],
input[type="number"] {
  -webkit-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button:disabled,
input:disabled,
textarea:disabled,
button.is-disabled,
input.is-disabled,
textarea.is-disabled {
  cursor: not-allowed;
}
textarea {
  width: 100%;
  resize: none;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
select.select,
.select select {
  appearance: none;
}
select.select::-ms-expand,
.select select::-ms-expand {
  display: none;
}
img:not([alt]) {
  filter: none;
}
#baguetteBox-overlay .full-image .img {
  max-height: 90%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
/*
  GRID
  ===============================================
  */
.rw {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -15px;
}
.cl {
  width: 100%;
  padding: 0 15px;
}
.cl-1 {
  max-width: 8.333333%;
}
.cl-2 {
  max-width: 16.666666%;
}
.cl-3 {
  max-width: 25%;
}
.cl-4 {
  max-width: 33.333333%;
}
.cl-5 {
  max-width: 41.666666%;
}
.cl-6 {
  max-width: 50%;
}
.cl-7 {
  max-width: 58.333333%;
}
.cl-8 {
  max-width: 66.666666%;
}
.cl-9 {
  max-width: 75%;
}
.cl-10 {
  max-width: 83.333333%;
}
.cl-11 {
  max-width: 91.666666%;
}
.cl-12 {
  max-width: 100%;
}
@media all and (max-width: 1759px) {
  .cl-nt-1 {
    max-width: 8.333333%;
  }
  .cl-nt-2 {
    max-width: 16.666666%;
  }
  .cl-nt-3 {
    max-width: 25%;
  }
  .cl-nt-4 {
    max-width: 33.333333%;
  }
  .cl-nt-5 {
    max-width: 41.666666%;
  }
  .cl-nt-6 {
    max-width: 50%;
  }
  .cl-nt-7 {
    max-width: 58.333333%;
  }
  .cl-nt-8 {
    max-width: 66.666666%;
  }
  .cl-nt-9 {
    max-width: 75%;
  }
  .cl-nt-10 {
    max-width: 83.333333%;
  }
  .cl-nt-11 {
    max-width: 91.666666%;
  }
  .cl-nt-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 1399px) {
  .cl-lp-1 {
    max-width: 8.333333%;
  }
  .cl-lp-2 {
    max-width: 16.666666%;
  }
  .cl-lp-3 {
    max-width: 25%;
  }
  .cl-lp-4 {
    max-width: 33.333333%;
  }
  .cl-lp-5 {
    max-width: 41.666666%;
  }
  .cl-lp-6 {
    max-width: 50%;
  }
  .cl-lp-7 {
    max-width: 58.333333%;
  }
  .cl-lp-8 {
    max-width: 66.666666%;
  }
  .cl-lp-9 {
    max-width: 75%;
  }
  .cl-lp-10 {
    max-width: 83.333333%;
  }
  .cl-lp-11 {
    max-width: 91.666666%;
  }
  .cl-lp-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 1279px) {
  .cl-tb-1 {
    max-width: 8.333333%;
  }
  .cl-tb-2 {
    max-width: 16.666666%;
  }
  .cl-tb-3 {
    max-width: 25%;
  }
  .cl-tb-4 {
    max-width: 33.333333%;
  }
  .cl-tb-5 {
    max-width: 41.666666%;
  }
  .cl-tb-6 {
    max-width: 50%;
  }
  .cl-tb-7 {
    max-width: 58.333333%;
  }
  .cl-tb-8 {
    max-width: 66.666666%;
  }
  .cl-tb-9 {
    max-width: 75%;
  }
  .cl-tb-10 {
    max-width: 83.333333%;
  }
  .cl-tb-11 {
    max-width: 91.666666%;
  }
  .cl-tb-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 1023px) {
  .cl-pd-1 {
    max-width: 8.333333%;
  }
  .cl-pd-2 {
    max-width: 16.666666%;
  }
  .cl-pd-3 {
    max-width: 25%;
  }
  .cl-pd-4 {
    max-width: 33.333333%;
  }
  .cl-pd-5 {
    max-width: 41.666666%;
  }
  .cl-pd-6 {
    max-width: 50%;
  }
  .cl-pd-7 {
    max-width: 58.333333%;
  }
  .cl-pd-8 {
    max-width: 66.666666%;
  }
  .cl-pd-9 {
    max-width: 75%;
  }
  .cl-pd-10 {
    max-width: 83.333333%;
  }
  .cl-pd-11 {
    max-width: 91.666666%;
  }
  .cl-pd-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 767px) {
  .cl-ph-1 {
    max-width: 8.333333%;
  }
  .cl-ph-2 {
    max-width: 16.666666%;
  }
  .cl-ph-3 {
    max-width: 25%;
  }
  .cl-ph-4 {
    max-width: 33.333333%;
  }
  .cl-ph-5 {
    max-width: 41.666666%;
  }
  .cl-ph-6 {
    max-width: 50%;
  }
  .cl-ph-7 {
    max-width: 58.333333%;
  }
  .cl-ph-8 {
    max-width: 66.666666%;
  }
  .cl-ph-9 {
    max-width: 75%;
  }
  .cl-ph-10 {
    max-width: 83.333333%;
  }
  .cl-ph-11 {
    max-width: 91.666666%;
  }
  .cl-ph-12 {
    max-width: 100%;
  }
}
/*
  HELPERS
  ===============================================
  */
.link {
  transition: all 0.25s linear;
}
.link:hover {
  opacity: 0.6;
}
.hide {
  display: none;
}
.icon {
  display: block;
}
.img {
  display: block;
  max-width: 100%;
}
picture {
  display: block;
}
picture img {
  width: 100%;
}
.tab {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.tab.is-active {
  display: block;
  visibility: visible;
  animation: tab-show 1s linear 0.01s 1 forwards;
}
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.is-process {
  position: relative;
}
.is-process:before,
.is-process:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 4;
}
.is-process:before {
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
}
.is-process:after {
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: #111c3e rgba(255,255,255,0) #111c3e rgba(255,255,255,0);
  margin-top: -15px;
  margin-left: -15px;
  z-index: 5;
  animation: process-rotate 1s ease-in-out infinite forwards;
}
.js-split-text .char,
.js-split-text .word {
  display: inline-block;
}
.js-split-text .char-space {
  display: inline-block;
  width: 0.25em;
}
#baguetteBox-overlay .full-image img {
  max-width: 1000px;
  max-height: 84%;
  box-shadow: none;
}
.baguetteBox-button {
  background-color: rgba(255,255,255,0);
}
.baguetteBox-button:hover,
.baguetteBox-button:focus {
  background-color: rgba(255,255,255,0);
}
.baguetteBox-button svg polyline {
  stroke: rgba(17,28,62,0.3);
  transition: all 0.25s linear;
}
.baguetteBox-button:hover svg polyline {
  stroke: #1d8eff;
}
.baguetteBox-button#close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/close.svg");
  width: 23px;
  height: 23px;
  top: 34px;
  right: 52px;
}
.baguetteBox-button#close-button svg {
  display: none;
}
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: inherit;
  line-height: 1;
}
@-moz-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes process-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes process-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes process-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes process-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*
  UI
  ===============================================
  */
.link-ui:hover {
  color: #1d8eff;
}
.link-ui-color {
  color: #1d8eff;
}
.cursor-ui {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: -50px;
  left: -50px;
  z-index: 9999999999;
  pointer-events: none;
  opacity: 0;
}
.cursor-ui.is-darken {
  mix-blend-mode: darken;
}
.cursor-ui__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(29,142,255,0);
  border-radius: 100%;
  font-size: 12px;
  color: #fff;
  transition: all 0.15s linear;
}
.cursor-ui__inner span {
  transform: scale(0);
  transition: all 0.3s ease-in;
}
.cursor-ui__outer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  background-color: rgba(29,142,255,0.9);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  transition: all 0.15s ease-in;
}
.cursor-ui.is-hover .cursor-ui__inner {
  background-color: rgba(29,142,255,0);
}
.cursor-ui.is-hover .cursor-ui__outer {
  transform: translate(-50%, -50%) scale(2);
}
.cursor-ui.is-hover-more .cursor-ui__inner {
  width: 120px;
  height: 120px;
  background-color: rgba(29,142,255,0.9);
}
.cursor-ui.is-hover-more .cursor-ui__inner span {
  transform: scale(1);
}
.cursor-ui.is-hover-more .cursor-ui__outer {
  transform: translate(-50%, -50%) scale(0);
}
.cursor-ui.is-disabled .cursor-ui__inner {
  background-color: rgba(204,204,204,0.9);
}
.cursor-ui.is-disabled .cursor-ui__outer {
  background-color: rgba(204,204,204,0.25);
  transform: translate(-50%, -50%) scale(0.65);
}
/* --- TITLES ------------------------- */
.section-title {
  text-align: center;
  margin: 0 auto 60px;
}
@media all and (max-width: 1023px) {
  .section-title {
    margin-bottom: 40px;
  }
}
@media all and (max-width: 767px) {
  .section-title {
    margin-bottom: 30px;
  }
}
.section-title__bottom {
  max-width: 500px;
  margin: 20px auto 0;
  font-size: 14px;
  line-height: 1.714285714285714;
  color: rgba(17,28,62,0.5);
}
@media all and (max-width: 767px) {
  .section-title__bottom {
    line-height: 1.571428571428571;
    margin-top: 13px;
  }
}
.section-title--light {
  color: #fff;
}
.section-title--light .section-title__bottom {
  color: rgba(255,255,255,0.5);
}
.title-ui {
  font-family: 'Playfair Display', serif;
  font-size: 48px;
  color: #111c3e;
  font-weight: normal;
}
@media all and (max-width: 1023px) {
  .title-ui {
    font-size: 30px;
  }
}
.title-ui--light {
  color: #fff;
}
.page-title-ui {
  font-family: 'Playfair Display', serif;
  font-size: 64px;
  line-height: 1.15625;
  color: #111c3e;
  font-weight: normal;
}
@media all and (max-width: 1023px) {
  .page-title-ui {
    font-size: 40px;
  }
}
@media all and (max-width: 767px) {
  .page-title-ui {
    font-size: 34px;
  }
}
.page-head {
  position: relative;
  margin-top: -30px;
}
@media all and (max-width: 1023px) {
  .page-head {
    display: flex;
  }
}
@media all and (max-width: 767px) {
  .page-head {
    margin-top: 0;
    flex-direction: column-reverse;
  }
}
.page-head.is-pattern:before {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 60%;
  left: 0;
  bottom: -135px;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  background-image: url("../images/page-head-pattern.jpg");
  z-index: -1;
}
@media all and (max-width: 767px) {
  .page-head.is-pattern:before {
    width: 100%;
    bottom: -60px;
  }
}
.page-head__left {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
}
@media all and (min-width: 1760px) {
  .page-head__left {
    height: 1050px;
  }
}
@media all and (max-width: 1279px) {
  .page-head__left {
    height: 600px;
  }
}
@media all and (max-width: 1023px) {
  .page-head__left {
    height: auto;
    max-width: 60%;
  }
}
@media all and (max-width: 767px) {
  .page-head__left {
    max-width: 100%;
  }
}
.page-head__right {
  position: absolute;
  width: 100%;
  max-width: 50%;
  right: 0;
  top: 0;
  height: 100%;
}
@media all and (max-width: 1023px) {
  .page-head__right {
    position: relative;
    right: auto;
    top: auto;
    height: auto;
    max-width: 40%;
  }
}
@media all and (max-width: 767px) {
  .page-head__right {
    max-width: 100%;
    margin-bottom: 30px;
  }
}
.page-head__right .swiper-container {
  width: 100%;
  height: 100%;
}
@media all and (max-width: 767px) {
  .page-head__right .swiper-container {
    height: 420px;
  }
}
.page-head__picture {
  height: 100%;
}
.page-head__picture img {
  height: 100%;
  object-fit: cover;
}
.page-head__picture--gray img {
  filter: grayscale(100%);
  transition: all 0.25s linear;
}
.page-head__box {
  max-width: 50%;
  padding-right: 4%;
}
@media all and (max-width: 1023px) {
  .page-head__box {
    max-width: 100%;
    padding: 50px 0;
  }
}
@media all and (max-width: 767px) {
  .page-head__box {
    padding: 0;
  }
}
.page-head__title {
  margin-bottom: 30px;
}
.page-head__txt {
  color: rgba(17,28,62,0.5);
  font-size: 14px;
  line-height: 1.714285714285714;
}
.page-head__txt p:not(:last-child) {
  margin-bottom: 1.7em;
}
.page-head__txt strong {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.296296296296296;
  color: #111c3e;
  font-weight: normal;
}
@media all and (max-width: 1023px) {
  .page-head__txt strong {
    font-size: 20px;
    line-height: 1.4;
  }
}
.page-head__link {
  margin-top: 40px;
}
.animation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 5;
  transform-origin: bottom;
}
.single-title {
  position: relative;
  z-index: 1;
}
.single-title__info {
  font-size: 14px;
  margin-bottom: 20px;
}
.single-title__info--light {
  color: rgba(17,28,62,0.5);
}
.single-title--push-down {
  margin-bottom: 30px;
}
/* --- SLIDER ------------------------- */
.slider-right-side {
  position: relative;
}
.slider-right-side__left {
  width: 100%;
  max-width: 1140px;
  padding: 0 20px;
  position: absolute;
  top: -12px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (max-width: 767px) {
  .slider-right-side__left {
    display: none;
  }
}
.slider-right-side__right {
  max-width: 66%;
  margin-left: auto;
  position: relative;
  z-index: 1;
}
@media all and (max-width: 1759px) {
  .slider-right-side__right {
    max-width: 70%;
  }
}
@media all and (max-width: 1399px) {
  .slider-right-side__right {
    max-width: 75%;
  }
}
@media all and (max-width: 767px) {
  .slider-right-side__right {
    margin-left: 20px;
    max-width: 100%;
  }
}
.slider-right-side .swiper-slide {
  max-width: 350px;
  padding-right: 50px;
}
@media all and (min-width: 1760px) {
  .slider-right-side .swiper-slide {
    max-width: 630px;
  }
}
@media all and (max-width: 767px) {
  .slider-right-side .swiper-slide {
    padding-right: 20px;
    max-width: 280px;
  }
}
.slider-pagination {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  display: inline-flex;
  align-items: flex-start;
  padding-top: 4px;
}
.slider-pagination .is-current {
  display: inline-block;
  font-size: 64px;
  line-height: 1;
  position: relative;
  top: -13px;
  padding-right: 8px;
}
.slider-pagination .is-total {
  line-height: 1;
  position: relative;
  top: -1px;
  padding-left: 4px;
}
.slider-centered {
  position: relative;
  z-index: 2;
}
.slider-centered .swiper-slide {
  max-width: 50%;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: scale(0.666666666666667);
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .slider-centered .swiper-slide {
    transform: scale(1);
    max-width: 88%;
    height: 230px;
    padding: 0 10px;
  }
}
.slider-centered .swiper-slide.is-prev {
  transform-origin: 75% 50%;
}
.slider-centered .swiper-slide.is-next {
  transform-origin: 25% 50%;
}
.slider-centered .swiper-slide.is-active {
  transform: scale(1);
}
.slider-centered .slide-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-centered .slide-picture__button {
  display: block;
  width: 100%;
}
.slider-centered .slider-nav {
  width: 22%;
}
.slider-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-dots__item {
  width: 8px;
  height: 8px;
  border: 1px solid rgba(255,255,255,0.4);
  border-radius: 50%;
  transition: all 0.25s linear;
}
.slider-dots__item:not(:last-child) {
  margin-right: 12px;
}
.slider-dots__item.is-active {
  background-color: #deb992;
}
.slider-nav {
  position: absolute;
  top: 0;
  height: 100%;
  width: 40%;
  background: rgba(255,255,255,0);
  z-index: 5;
}
.slider-nav.prev {
  left: 0;
}
.slider-nav.next {
  right: 0;
}
/* --- FORMS -------------------------- */
.button,
.input {
  display: block;
  width: 100%;
}
.fg {
  position: relative;
}
.fg .error-message {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: #fa5252;
  font-size: 0.8em;
  margin-top: 2px;
}
.btn-ui {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 100%;
  max-width: 200px;
  font-family: 'Noto Sans', sans-serif;
  background-color: #111c3e;
  color: #fff;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}
.btn-ui:after {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0;
  background-color: #deb992;
  transform: translate(50%, 50%) rotate(45deg);
  transition: all 0.6s linear;
}
.btn-ui:hover:after {
  transform: translate(50%, 50%) rotate(45deg) scale(16);
}
.btn-ui span {
  position: relative;
  z-index: 1;
}
.btn-ui--bordered-light {
  border: 1px solid rgba(255,242,242,0.3);
  background: none;
}
.btn-ui-square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255,255,255,0.2);
}
.btn-ui-square .icon {
  fill: #fff;
  position: relative;
  z-index: 1;
}
.btn-ui-square:hover {
  border-color: #fff;
}
.btn-ui-square--stroke {
  position: relative;
}
.btn-ui-square--stroke:hover {
  border-color: rgba(255,255,255,0.2);
}
.btn-ui-square .icon-border {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 40px;
  height: 40px;
  fill: rgba(255,255,255,0);
}
.btn-ui-square .icon-border rect {
  width: 100%;
  height: 100%;
  stroke: #fff;
  stroke-width: 1;
  transition: all 0.25s linear;
}
.btn-ui-square .icon-border--top-right rect {
  stroke-dasharray: 20, 110;
  stroke-dashoffset: -30;
}
.btn-ui-square .icon-border--bottom-left rect {
  stroke-dasharray: 20, 110;
  stroke-dashoffset: -110;
}
.btn-ui-square .icon-border--bottom-left-dot rect {
  stroke-dasharray: 0, 112;
  stroke-dashoffset: -120;
}
.btn-ui-square:hover .icon-border--top-right rect {
  stroke-dasharray: 160, 0;
}
.btn-ui-square:hover .icon-border--bottom-left rect,
.btn-ui-square:hover .icon-border--bottom-left-dot rect {
  stroke-dasharray: 160, 0;
}
.btn-ui-square .icon-social {
  width: 16px;
  height: 16px;
}
.btn-ui-square .icon-slider {
  width: 14px;
  height: 14px;
}
.btn-ui-square .icon-weather {
  width: 20px;
  height: 15px;
}
.btn-ui-square.is-disabled {
  opacity: 0.25;
}
.btn-ui-square--dark {
  border-color: rgba(17,28,62,0.2);
}
.btn-ui-square--dark .icon {
  fill: #111c3e;
}
.btn-ui-square--dark:hover .icon-border rect {
  stroke: #111c3e;
}
.btn-ui-square-group {
  display: inline-flex;
  align-items: center;
}
.btn-ui-square-group .btn-ui-square:not(:last-child) {
  margin-right: 20px;
}
.input-ui {
  display: block;
  width: 100%;
  height: 50px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.259259259259259;
}
@media all and (max-width: 767px) {
  .input-ui {
    font-size: 20px;
    line-height: 1.35;
  }
}
.input-ui.error {
  border-bottom-color: #fa5252;
}
.label-ui {
  display: block;
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.259259259259259;
}
@media all and (max-width: 767px) {
  .label-ui {
    font-size: 20px;
    line-height: 1.35;
  }
}
.fg-labeled {
  position: relative;
}
.fg-labeled .input-ui {
  position: relative;
  z-index: 2;
}
.fg-labeled .label-ui {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  transition: all 0.25s linear;
}
.fg-labeled .input-ui--ta ~ .label-ui {
  top: 3px;
  transform: none;
}
.fg-labeled.is-focus .label-ui,
.fg-labeled .input-ui:focus ~ .label-ui {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  line-height: 1.714285714285714;
  top: 0;
  transform: none;
}
/* --- PAGE --------------------------- */
.page-wrapper {
  padding: 25px 0 80px;
}
@media all and (max-width: 767px) {
  .page-wrapper {
    padding: 0 0 30px;
  }
}
.section:not(:last-child) {
  margin-bottom: 60px;
}
.section-w-separator {
  position: relative;
  padding-bottom: 80px;
}
@media all and (max-width: 1023px) {
  .section-w-separator {
    padding-bottom: 40px;
  }
}
.section-w-separator:after {
  content: '';
  display: block;
  position: absolute;
  width: 60px;
  height: 1px;
  background-color: #deb992;
  bottom: 0;
  left: 50%;
  margin-left: -30px;
}
.head-navigator {
  font-size: 14px;
  font-size: 14px;
  line-height: 2.285714285714286;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 35px;
}
@media all and (max-width: 767px) {
  .head-navigator {
    margin-top: 15px;
    margin-bottom: 30px;
  }
}
.head-navigator__item {
  border-bottom: 1px solid rgba(255,255,255,0);
}
.head-navigator__item:not(:last-child) {
  margin-right: 24px;
}
.head-navigator__item:not([href]),
.head-navigator__item:not([type="button"]) {
  color: rgba(17,28,62,0.5);
}
.head-navigator__item.is-current {
  color: #111c3e;
  border-bottom: 1px solid #deb992;
}
.head-navigator__item[href],
.head-navigator__item[type="button"] {
  color: #111c3e;
  line-height: inherit;
}
.head-navigator__item[href]:hover,
.head-navigator__item[type="button"]:hover {
  color: #1d8eff;
}
.head-navigator__item i {
  font-style: normal;
  color: rgba(17,28,62,0.5);
}
.btn-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  background-color: #1d8eff;
  border-radius: 100%;
  margin-top: 80px;
}
@media all and (max-width: 767px) {
  .btn-loader {
    margin-top: 30px;
    width: 80px;
    height: 80px;
  }
}
.btn-loader:hover {
  background-color: #111c3e;
}
.btn-loader span {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  border: 1px solid #fff;
}
.btn-loader span:not(:last-child) {
  margin-right: 8px;
}
.btn-loader.is-active span:first-child {
  animation: btn-loader-dots 0.8s ease-in-out infinite alternate forwards;
}
.btn-loader.is-active span:nth-child(2) {
  animation: btn-loader-dots 0.8s ease-in-out 0.5s infinite alternate forwards;
}
.btn-loader.is-active span:last-child {
  animation: btn-loader-dots 0.8s ease-in-out 1s infinite alternate forwards;
}
.share-ui {
  position: relative;
  padding-top: 40px;
  margin-top: 60px;
}
.share-ui:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #deb992;
  width: 60px;
  height: 1px;
}
.share-ui__title {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.296296296296296;
  margin-bottom: 25px;
}
.hero-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  position: relative;
}
@media all and (min-width: 1760px) {
  .hero-video {
    height: 900px;
  }
}
@media all and (max-width: 1023px) {
  .hero-video {
    height: auto;
  }
}
.hero-video img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.hero-video .play-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.play-video-icon {
  display: block;
  width: 142px;
  height: 142px;
}
@media all and (max-width: 767px) {
  .play-video-icon {
    width: 70px;
    height: 70px;
  }
}
.play-video-icon__el {
  width: 100%;
  height: 100%;
}
.play-video-icon__bg {
  fill: #deb992;
  transition: all 0.25s linear;
}
.play-video-icon__arrow {
  fill: #fff;
}
.play-video-icon:hover .play-video-icon__bg {
  fill: #111c3e;
}
.play-video-button {
  position: relative;
}
.play-video-button .play-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.section-picture {
  padding: 60px 0;
  position: relative;
  margin-bottom: 60px;
}
@media all and (max-width: 767px) {
  .section-picture {
    padding: 30px 0;
  }
}
.section-picture:before {
  content: '';
  display: block;
  position: absolute;
  width: 38%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #111c3e;
}
.section-picture__pic {
  display: block;
  width: 100%;
  height: 620px;
  position: relative;
  z-index: 1;
}
@media all and (min-width: 1760px) {
  .section-picture__pic {
    height: 930px;
  }
}
@media all and (max-width: 1023px) {
  .section-picture__pic {
    height: auto;
  }
}
.section-picture__pic .play-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.section-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.line-slash-sep {
  display: flex;
  align-items: center;
}
.line-slash-sep .slash-sep {
  display: inline-block;
  width: 1px;
  height: 23px;
  background-color: #fff;
  transform-origin: 50% 50%;
  transform: rotate(30deg);
  opacity: 0.3;
  margin: 0 20px;
}
.tabs-ui {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media all and (max-width: 1023px) {
  .tabs-ui {
    justify-content: flex-start;
  }
}
.tabs-ui__item {
  width: auto;
  font-size: 14px;
  line-height: 1.714285714285714;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255,255,255,0);
  margin: 0 20px 10px;
}
@media all and (max-width: 1023px) {
  .tabs-ui__item {
    padding-bottom: 0;
  }
}
@media all and (max-width: 767px) {
  .tabs-ui__item {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.tabs-ui__item:hover {
  color: #1d8eff;
}
.tabs-ui__item.is-active {
  color: #deb992;
  border-bottom: 1px solid #deb992;
}
.tab-ui {
  padding-top: 20px;
}
.tab-ui__title {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.296296296296296;
  margin-bottom: 37px;
}
@media all and (max-width: 767px) {
  .tab-ui__title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.tab-ui__cols {
  margin: 0 -50px;
}
@media all and (max-width: 1023px) {
  .tab-ui__cols {
    margin: 0 -25px;
  }
}
.tab-ui__cols--middle {
  align-items: center;
}
@media all and (max-width: 767px) {
  .tab-ui__cols--picture {
    flex-direction: column-reverse;
  }
}
@media all and (max-width: 767px) {
  .tab-ui__cols--picture .tab-ui__picture {
    margin-bottom: 30px;
  }
}
.tab-ui__col {
  padding: 0 50px;
}
@media all and (max-width: 1023px) {
  .tab-ui__col {
    padding: 0 25px;
  }
}
.tab-ui__txt {
  padding-bottom: 45px;
}
.tab-ui__txt--light {
  font-size: 14px;
  line-height: 1.714285714285714;
  color: rgba(17,28,62,0.5);
}
.tab-ui__lines {
  font-size: 14px;
  line-height: 1;
}
.tab-ui__lines p {
  display: flex;
}
.tab-ui__lines p:not(:last-child) {
  margin-bottom: 1.2em;
}
.tab-ui__lines p i {
  display: block;
  flex-grow: 1;
  border-bottom: 1px dashed rgba(233,233,233,0.2);
  margin: 0 10px;
}
.tab-ui__lines--dark p i {
  border-bottom-color: rgba(233,233,233,0.8);
}
.tab-ui__picture {
  width: 100%;
  height: 100%;
}
.tab-ui__picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-left: auto;
}
@media all and (max-width: 767px) {
  .tab-ui__picture img {
    height: 100%;
  }
}
.tab-ui__slider {
  width: 376px;
  height: 540px;
  margin-right: 0;
  margin-left: auto;
}
@media all and (max-width: 767px) {
  .tab-ui__slider {
    width: 292px;
    height: 420px;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
.spec-list-ui {
  font-size: 14px;
  line-height: 1.428571428571429;
}
@media all and (max-width: 767px) {
  .spec-list-ui__item:not(:last-child) {
    margin-bottom: 50px;
  }
}
.spec-list-ui p:not(:last-child) {
  margin-bottom: 1.5em;
}
.spec-list-ui ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style: disc;
  padding-left: 1.2em;
  color: rgba(17,28,62,0.5);
}
.spec-list-ui ul li:not(:last-child) {
  margin-bottom: 1em;
}
.spec-list-ui--light ul {
  color: rgba(255,255,255,0.5);
}
.text {
  font-size: 14px;
  line-height: 1.428571428571429;
}
.text p:not(:last-child),
.text ul:not(:last-child),
.text ol:not(:last-child) {
  margin-bottom: 1.1em;
}
.text ul,
.text ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.text ul li:not(:last-child),
.text ol li:not(:last-child) {
  margin-bottom: 1.1em;
}
.text ul li {
  position: relative;
  padding-left: 1.3em;
}
.text ul li:before {
  content: '';
  display: block;
  position: absolute;
  top: 0.55em;
  left: 0;
  width: 4px;
  height: 4px;
  background-color: #deb992;
  border-radius: 100%;
  transition: all 0.25s linear;
}
.text ol {
  counter-reset: olCounter;
}
.text ol li {
  position: relative;
}
.text ol li:before {
  counter-increment: olCounter;
  content: counter(olCounter) ".";
  margin-right: 0.5em;
  display: inline-block;
  vertical-align: middle;
  color: #deb992;
}
.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  font-family: 'Playfair Display', serif;
  color: #111c3e;
  font-size: 27px;
  line-height: 1.296296296296296;
}
@media all and (max-width: 767px) {
  .text h1,
  .text h2,
  .text h3,
  .text h4,
  .text h5,
  .text h6 {
    font-size: 20px;
  }
}
.text h1:not(:last-child),
.text h2:not(:last-child),
.text h3:not(:last-child),
.text h4:not(:last-child),
.text h5:not(:last-child),
.text h6:not(:last-child) {
  margin-bottom: 15px;
}
.ps {
  position: relative;
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  padding-right: 30px;
}
.ps__rail-y {
  position: absolute;
  right: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  background-color: #f2f2f2;
  border-radius: 5px;
  width: 5px;
}
.ps__thumb-y {
  position: absolute;
  background-color: #aaa;
  border-radius: 5px;
  width: 100%;
  right: 0;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
}
@-moz-keyframes btn-loader-move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes btn-loader-move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-o-keyframes btn-loader-move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes btn-loader-move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-moz-keyframes btn-loader-dots {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes btn-loader-dots {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes btn-loader-dots {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes btn-loader-dots {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*
  SECTION LOADER
  ===============================================
  */
#loader {
  display: block;
  position: relative;
  z-index: 1000;
  opacity: 1;
  visibility: visible;
}
#loader.is-done {
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s linear;
  z-index: -1;
}
.section-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #0b1125;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/loader.jpg");
}
.section-loader__body {
  width: 100%;
  text-align: center;
}
.section-loader__compas {
  position: relative;
  height: 180px;
  width: 180px;
  margin: 0 auto 30px;
}
.section-loader__compas:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/loader-compas.svg");
  animation: loader-compas-rotate 10s linear infinite forwards;
}
.section-loader__header {
  font-family: 'Playfair Display', serif;
  margin-bottom: 15px;
}
.section-loader__title {
  text-transform: uppercase;
  font-size: 27px;
  line-height: 1.185185185185185;
  margin-bottom: 5px;
  opacity: 0;
  animation: loader-fade-up 0.6s ease-in 0.1s 1 forwards;
}
.section-loader__subtitle {
  font-size: 18px;
  line-height: 1.777777777777778;
  opacity: 0;
  animation: loader-fade-up 0.6s ease-in 0.2s 1 forwards;
}
.section-loader__counter {
  opacity: 0;
  animation: loader-fade-up 0.6s ease-in 0.3s 1 forwards;
}
@-moz-keyframes loader-compas-rotate {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes loader-compas-rotate {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes loader-compas-rotate {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes loader-compas-rotate {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes loader-fade-up {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes loader-fade-up {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes loader-fade-up {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes loader-fade-up {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
/*
  HEADER
  ===============================================
  */
.header {
  padding: 30px 0;
  position: relative;
  z-index: 10;
}
@media all and (max-width: 767px) {
  .header {
    padding: 20px 0;
  }
}
.header__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 12;
}
.header.is-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
}
.header-logo {
  display: block;
  max-width: 128px;
  max-height: 30px;
}
.header-logo__item--light {
  display: none;
}
.header-logo__item--dark {
  display: block;
}
.header-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-buttons__item {
  display: flex;
  align-items: center;
}
.header-buttons__item:not(:last-child) {
  margin-right: 40px;
}
.header-search-button .icon {
  width: 18px;
  height: 18px;
  fill: #111c3e;
}
.header-burger-button {
  position: relative;
  width: 30px;
  height: 30px;
}
.header-burger-button:before,
.header-burger-button:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #111c3e;
  left: 0;
  transition: all 0.25s linear;
}
.header-burger-button:before {
  top: 10px;
}
.header-burger-button:after {
  bottom: 10px;
}
.header-burger-button.is-active:before,
.header-burger-button.is-active:after {
  top: 50%;
  bottom: auto;
}
.header-burger-button.is-active:before {
  transform: rotate(45deg);
}
.header-burger-button.is-active:after {
  transform: rotate(-45deg);
}
.header.is-light .header-logo__item {
  display: none;
}
.header.is-light .header-logo__item--light {
  display: block;
}
.header.is-light .header-burger-button:before,
.header.is-light .header-burger-button:after {
  background-color: #fff;
}
.header.is-light .header-search-button .icon {
  fill: #fff;
}
.header.is-active-light .header-logo__item {
  display: none;
}
.header.is-active-light .header-logo__item--light {
  display: block;
}
.header.is-active-light .header-burger-button:before,
.header.is-active-light .header-burger-button:after {
  background-color: #fff;
}
.header.is-active-dark .header-logo__item {
  display: none;
}
.header.is-active-dark .header-logo__item--dark {
  display: block;
}
.header.is-active-dark .header-burger-button:before,
.header.is-active-dark .header-burger-button:after {
  background-color: #111c3e;
}
.header.is-active-dark .header-search-button,
.header.is-active-light .header-search-button {
  display: none;
}
/* --- HEADER MODAL ------------------ */
.header-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% auto;
  background-image: url("../images/bg-header-active.png");
  overflow: hidden;
  z-index: 10;
  padding-top: 60px;
  padding-bottom: 15px;
}
.header-modal__body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/*
  FOOTER
  ===============================================
  */
.footer {
  background-color: #111c3e;
  color: rgba(255,255,255,0.3);
  text-align: center;
  font-size: 14px;
  padding: 60px 0 65px;
}
@media all and (max-width: 1023px) {
  .footer {
    padding: 45px 0 42px;
  }
}
.footer:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 246px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  background-image: url("../images/bg-pattern-footer.png");
}
.footer__title {
  position: relative;
  z-index: 1;
  margin-bottom: 28px;
}
@media all and (max-width: 1023px) {
  .footer__title {
    margin-bottom: 16px;
  }
}
.footer__form {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}
@media all and (max-width: 1023px) {
  .footer__form {
    margin-bottom: 34px;
  }
}
.footer__socials {
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}
@media all and (max-width: 1023px) {
  .footer__socials {
    margin-bottom: 20px;
  }
}
.footer__copyright {
  position: relative;
  z-index: 1;
}
.footer .link:hover {
  opacity: 1;
  color: #fff;
}
.f-form {
  position: relative;
}
.f-form.is-process:before {
  background-color: #111c3e;
}
.f-form.is-process:after {
  border-color: #fff rgba(255,255,255,0) #fff rgba(255,255,255,0);
}
.f-form__group {
  max-width: 300px;
  margin: 0 auto;
  position: relative;
}
.f-form__input {
  height: 70px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  color: #fff;
  font-size: 14px;
  padding-right: 50px;
}
.f-form__input.error {
  border-color: #ff6b6b;
}
.f-form__button {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255,255,255,0.2);
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
}
.f-form__button .icon {
  width: 14px;
  height: 14px;
  margin: auto;
  fill: #fff;
}
.f-form__bottom {
  font-size: 12px;
  padding-top: 28px;
}
@media all and (max-width: 1023px) {
  .f-form__bottom {
    padding-top: 22px;
  }
}
.f-form__error {
  position: absolute;
  top: 0;
  bottom: 1px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #111c3e;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
.f-form__success {
  position: absolute;
  top: -5px;
  left: 0;
  bottom: -5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #111c3e;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
.f-form.is-sent .f-form__success {
  opacity: 1;
  visibility: visible;
}
.f-form.is-fail .f-form__error {
  opacity: 1;
  visibility: visible;
}
.f-copyright {
  max-width: 514px;
  margin: 0 auto;
  line-height: 1.714285714285714;
}
.f-copyright__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 767px) {
  .f-copyright__item {
    justify-content: center;
  }
}
.f-copyright__item:not(:last-child) {
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  .f-copyright__item--one {
    display: none;
  }
}
@media all and (max-width: 767px) {
  .f-copyright__tel,
  .f-copyright__email {
    display: none;
  }
}
.cookie-agree {
  position: fixed;
  bottom: 20px;
  left: 50px;
  right: 50px;
  color: #fff;
  display: none;
}
@media all and (max-width: 1023px) {
  .cookie-agree {
    left: 5px;
    right: 5px;
  }
}
@media all and (max-width: 767px) {
  .cookie-agree {
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.cookie-agree.is-active {
  display: block;
  z-index: 100;
}
.cookie-agree__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #deb992;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.428571428571429;
  padding: 5px;
}
@media all and (max-width: 767px) {
  .cookie-agree__body {
    flex-direction: column;
    font-size: 12px;
    padding: 15px 5px;
  }
}
.cookie-agree__txt {
  padding: 0 35px;
  flex-grow: 1;
}
@media all and (max-width: 1023px) {
  .cookie-agree__txt {
    padding: 0 15px;
  }
}
@media all and (max-width: 767px) {
  .cookie-agree__txt {
    margin-bottom: 15px;
  }
}
.cookie-agree__control {
  flex: none;
  width: 100%;
  max-width: 200px;
}
.cookie-agree__btn {
  background-color: #fff;
  color: #111c3e;
  height: 54px;
}
@media all and (max-width: 767px) {
  .cookie-agree__btn {
    height: 34px;
  }
}
.cookie-agree__btn:hover {
  background-color: #111c3e;
  color: #fff;
}
/*
  BLOCK MENU
  ===============================================
  */
.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  z-index: 10;
  background-color: rgba(11,17,37,0.98);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/menu-bg.jpg");
  color: #fff;
  padding-top: 60px;
}
.header-menu:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(11,17,37,0.96);
  z-index: 0;
}
.header-menu__body {
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
@media all and (max-width: 1023px) {
  .header-menu__body {
    padding: 0 20px;
  }
}
.header-menu__top {
  flex-grow: 1;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 25px;
}
.header-menu__contacts {
  font-size: 14px;
  line-height: 1.714285714285714;
  max-width: 800px;
  margin: 0 auto;
}
@media all and (max-width: 1023px) {
  .header-menu__contacts {
    margin-bottom: 36px;
  }
}
@media all and (max-width: 767px) {
  .header-menu__contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.header-menu__contacts-socials {
  display: none;
}
@media all and (max-width: 767px) {
  .header-menu__contacts-socials {
    display: block;
  }
}
.header-menu__bottom {
  position: relative;
  padding: 40px 0 30px;
}
@media all and (max-width: 1023px) {
  .header-menu__bottom {
    padding: 0;
  }
}
.header-menu__button {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media all and (max-width: 1023px) {
  .header-menu__button {
    position: relative;
    bottom: auto;
    right: auto;
  }
}
.main-menu {
  font-family: 'Playfair Display', serif;
  display: inline-block;
  font-size: 64px;
  line-height: 1.625;
  column-count: 2;
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 1023px) {
  .main-menu {
    font-size: 42px;
    line-height: 1.904761904761905;
  }
}
@media all and (max-width: 767px) {
  .main-menu {
    font-size: 30px;
    line-height: 1.666666666666667;
    column-count: 1;
  }
}
@media all and (max-width: 767px) {
  .main-menu__item--socials {
    display: none;
  }
}
.main-menu__link:hover {
  color: #1d8eff;
}
.main-menu__item.is-subs > .main-menu__link {
  position: relative;
  padding-right: 30px;
}
.main-menu__item.is-subs > .main-menu__link:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/arrow-menu-sub.svg");
  top: 62%;
  right: 0;
  margin-top: -6px;
}
.main-menu__back-link {
  font-size: 14px;
  position: relative;
  padding-left: 18px;
  font-family: 'Noto Sans', sans-serif;
}
.main-menu__back-link:before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/arrow-menu-sub.svg");
  transform: rotate(180deg);
  top: 50%;
  margin-top: -6px;
  left: 0;
}
.main-menu__subs {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  visibility: hidden;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .main-menu__subs {
    margin-top: 16px;
  }
}
.main-menu__sub {
  flex-grow: 1;
  max-height: 100%;
  width: 100%;
  margin-top: 25px;
  overflow: hidden;
  position: relative;
}
@media all and (max-width: 767px) {
  .main-menu__sub {
    margin-top: 15px;
  }
}
.main-menu__sub-list {
  column-count: 2;
  column-fill: auto;
  display: inline-block;
}
@media all and (max-width: 767px) {
  .main-menu__sub-list {
    column-count: 1;
  }
}
.main-menu__sub-list .main-menu__item {
  font-size: 27px;
  line-height: 1.62962962962963;
}
@media all and (max-width: 767px) {
  .main-menu__sub-list .main-menu__item {
    font-size: 18px;
  }
}
.main-menu.is-subs-active .main-menu__item {
  visibility: hidden;
}
.main-menu__item.is-active .main-menu__subs {
  left: 0;
  visibility: visible;
}
.main-menu__item.is-active > .main-menu__link {
  visibility: hidden;
}
.main-menu.is-subs-active .main-menu__item.is-active {
  visibility: visible;
}
.main-menu.is-subs-active .main-menu__item.is-active .main-menu__item {
  visibility: visible;
}
/*
  BLOCK SEARCH
  ===============================================
  */
.header-search {
  overflow-y: auto;
}
.h-search-form__group {
  position: relative;
}
.h-search-form__input {
  font-family: 'Playfair Display', serif;
  font-size: 48px;
  line-height: 1.541666666666667;
  padding-right: 32px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
}
.h-search-form__input.error {
  border-bottom-color: #fa5252;
}
@media all and (max-width: 1023px) {
  .h-search-form__input {
    font-size: 30px;
    line-height: 1.666666666666667;
    padding-bottom: 5px;
  }
}
.h-search-form__button {
  position: absolute;
  height: 100%;
  width: 18px;
  top: 0;
  right: 0;
}
.h-search-form__button .icon {
  width: 18px;
  height: 18px;
  fill: #111c3e;
}
.h-search-results {
  padding-top: 50px;
}
.h-search-results .swiper-slide {
  max-width: 350px;
  padding-right: 50px;
}
@media all and (max-width: 767px) {
  .h-search-results .swiper-slide {
    max-width: 280px;
    padding-right: 20px;
  }
}
/*
  SECTION HERO
  ===============================================
  */
#bubbles {
  position: relative;
  width: 100%;
  height: 100%;
}
.section-hero {
  pointer-events: auto;
  height: 100vh;
  min-height: 480px;
  max-height: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #111c3e;
  position: relative;
  overflow: hidden;
}
@media all and (min-width: 1760px) {
  .section-hero {
    max-height: 1280px;
  }
}
@media all and (max-width: 767px) {
  .section-hero {
    max-height: 670px;
  }
}
.section-hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.section-hero__bg-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.section-hero__bg-pic.is-mob {
  display: none;
}
@media all and (max-width: 767px) {
  .section-hero__bg-pic.is-mob {
    display: block;
  }
}
@media all and (max-width: 767px) {
  .section-hero__bg-pic.is-desk {
    display: none;
  }
}
.section-hero__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media all and (max-width: 767px) {
  .section-hero__bg img {
    object-fit: none;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: auto;
  }
}
.section-hero .canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: #263f8c;
}
.hero {
  width: 100%;
  color: #fff;
  font-size: 14px;
  position: relative;
}
@media all and (max-width: 767px) {
  .hero__top {
    padding-bottom: 30px;
  }
}
.hero__top-body {
  max-width: 460px;
  position: relative;
  z-index: 2;
}
@media all and (max-width: 767px) {
  .hero__top-body {
    max-width: 335px;
  }
}
.hero__timer {
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  .hero__timer {
    margin-bottom: 8px;
  }
}
.hero__title {
  font-family: 'Playfair Display', serif;
  font-size: 64px;
  line-height: 1.15625;
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  .hero__title {
    font-size: 36px;
    line-height: 1.222222222222222;
    margin-bottom: 12px;
  }
}
@media all and (max-width: 767px) {
  .hero__info {
    font-size: 14px;
  }
}
.hero__bottom {
  padding: 40px 0 30px;
  position: relative;
  z-index: 2;
}
@media all and (max-width: 767px) {
  .hero__bottom {
    display: none;
  }
}
.hero__button {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}
@media all and (max-width: 1023px) {
  .hero__button {
    position: relative;
  }
}
.hero-button {
  width: 600px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 16px;
}
@media all and (max-width: 1023px) {
  .hero-button {
    height: 65px;
    margin-left: auto;
  }
}
@media all and (max-width: 767px) {
  .hero-button {
    width: 100%;
    padding-left: 32px;
  }
}
.hero-button .icon {
  fill: #111c3e;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .hero-button .icon {
    left: -20px;
  }
}
.hero-button__text {
  position: relative;
  z-index: 1;
}
.hero-button:hover .icon {
  fill: #deb992;
}
.hero-button__pin {
  display: block;
  width: 206px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: -150px;
  z-index: 1;
  margin-top: -6px;
}
@media all and (max-width: 767px) {
  .hero-button__pin {
    width: 140px;
    height: 10px;
    margin-top: -5px;
    left: -80px;
  }
}
.hero-button__pin:before,
.hero-button__pin:after {
  content: '';
  display: block;
  position: absolute;
}
.hero-button__pin:before {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #fff;
  right: 0;
  top: 50%;
  margin-top: -6px;
  z-index: 5;
}
@media all and (max-width: 767px) {
  .hero-button__pin:before {
    width: 10px;
    height: 10px;
  }
}
.hero-button__pin:after {
  height: 1px;
  left: 0;
  right: 12px;
  top: 6px;
  background-color: rgba(255,255,255,0.2);
}
@media all and (max-width: 767px) {
  .hero-button__pin:after {
    right: 10px;
    top: 4px;
  }
}
.hero-button__pin span {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  right: -14px;
  margin-top: -20px;
  border-radius: 50%;
  background: #1d8eff;
  animation: hero-button-pin-pulse 3s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  opacity: 0;
  z-index: 3;
}
@media all and (max-width: 767px) {
  .hero-button__pin span {
    right: -15px;
    margin-top: -21px;
  }
}
.hero-button__pin span:before,
.hero-button__pin span:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 50%;
  background: #fff;
  animation-iteration-count: infinite;
  opacity: 0;
}
.hero-button__pin span:before {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  animation: hero-button-pin-pulse 2.5s;
  animation-delay: 1.5s;
  opacity: 0.2;
  z-index: 2;
}
.hero-button__pin span:after {
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  animation: hero-button-pin-pulse 3s;
  animation-delay: 1s;
  opacity: 0.2;
  z-index: 1;
}
.hero-button--current .hero-button__pin:before {
  background: #1d8eff;
  border-color: rgba(255,255,255,0);
}
.hero-button--current .hero-button__pin span {
  display: block;
}
.timer-tick {
  display: inline-flex;
}
.timer-tick__group {
  display: inline-flex;
}
.timer-tick__group:not(:last-child) {
  margin-right: 0.3em;
}
.timer-tick__type {
  margin-left: 0.3em;
}
@-moz-keyframes hero-button-pin-pulse {
  from {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes hero-button-pin-pulse {
  from {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
@-o-keyframes hero-button-pin-pulse {
  from {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes hero-button-pin-pulse {
  from {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
/*
  SECTION EVENTS
  ===============================================
  */
.section-events {
  padding: 80px 0 0;
}
@media all and (max-width: 767px) {
  .section-events {
    padding: 45px 0 20px;
  }
}
.events-slider {
  padding: 90px 0;
  position: relative;
  color: #fff;
}
@media all and (max-width: 767px) {
  .events-slider {
    padding: 0;
  }
}
.events-slider:before {
  content: '';
  display: block;
  position: absolute;
  width: 37%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #111c3e;
}
@media all and (max-width: 767px) {
  .events-slider:before {
    display: none;
  }
}
.eventCard {
  display: block;
  height: 420px;
  padding: 27px 10px 32px 30px;
  color: #fff;
  background-color: #111c3e;
  position: relative;
  overflow: hidden;
}
@media all and (min-width: 1760px) {
  .eventCard {
    height: 756px;
  }
}
@media all and (max-width: 767px) {
  .eventCard {
    height: 400px;
  }
}
.eventCard__picture {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.25s linear;
}
.eventCard__picture img {
  height: 100%;
  object-fit: cover;
}
.eventCard__body {
  position: relative;
  z-index: 1;
  height: 100%;
}
.eventCard__date {
  font-size: 12px;
  margin-bottom: 10px;
}
.eventCard__title {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
}
.eventCard__label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #deb992;
  color: #fff;
  z-index: 2;
  transform: translateY(100%);
  font-size: 0.8em;
  transition: all 0.32s ease-in-out;
}
.eventCard:hover .eventCard__picture {
  transform: scale(1.05);
}
.eventCard:hover .eventCard__label {
  transform: translateY(0);
}
/*
  SECTION ARCHIVE
  ===============================================
  */
.section-archive {
  padding: 50px 0 30px;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 76%, rgba(255,255,255,0) 76%, #111c3e 76%, #111c3e 100%);
}
@media all and (max-width: 767px) {
  .section-archive {
    padding: 25px 0 0;
  }
}
.archiveSlide {
  width: 100%;
  height: 100%;
  position: relative;
}
.archiveSlide .archiveCard {
  width: 100%;
  height: 100%;
}
.archiveCard {
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 420px;
  overflow: hidden;
}
@media all and (min-width: 1760px) {
  .archiveCard {
    height: 504px;
  }
}
@media all and (max-width: 1023px) {
  .archiveCard {
    height: 320px;
  }
}
@media all and (max-width: 767px) {
  .archiveCard {
    height: 230px;
  }
}
.archiveCard__picture {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.25s linear;
}
.archiveCard__picture img {
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  opacity: 0.5;
  transition: all 0.25s linear;
}
.archiveCard__body {
  position: relative;
  width: 100%;
  z-index: 2;
  color: #fff;
  padding: 30px 30px 35px;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .archiveCard__body {
    padding: 20px;
  }
}
.archiveCard__title {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
}
@media all and (max-width: 767px) {
  .archiveCard__title {
    font-size: 18px;
  }
}
.archiveCard__info {
  font-size: 12px;
  padding-top: 15px;
}
@media all and (max-width: 767px) {
  .archiveCard__info {
    padding-top: 10px;
  }
}
.archiveCard:hover .archiveCard__body {
  opacity: 1;
}
.archiveCard:hover .archiveCard__picture {
  transform: scale(1.05);
}
.archiveCard:hover .archiveCard__picture img {
  opacity: 1;
}
.archiveCard.is-hover-colored:hover .archiveCard__picture img {
  filter: grayscale(0);
}
/*
  SECTION MAPS
  ===============================================
  */
#maps-slider {
  pointer-events: none;
}
.earth-overlay {
  pointer-events: all !important;
  transform: scale(1);
}
.globus__picWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #3299ff;
  width: 150px;
  height: 150px;
  left: 50%;
  top: 50%;
  z-index: 10;
  border-radius: 100%;
  pointer-events: none !important;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: all 0.5s ease;
}
.globus__picWrap_img {
  width: 100%;
  height: calc(100% - 35px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.globus__picWrap_city {
  color: #fff;
  text-align: center;
  font-size: 14px;
  padding: 8px;
}
@media all and (max-width: 767px) {
  .globus__picWrap_city {
    font-size: 12px;
  }
}
.globus__country {
  color: #fff;
  font-size: 11px;
  opacity: 0.7;
}
.globus__baloon {
  display: block;
  position: absolute;
  background: #fff;
  color: #1d8eff;
  font-size: 14px;
  padding: 10px;
  top: 50%;
  left: 40px;
  width: auto;
  white-space: nowrap;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.15);
  transform: translateY(-50%);
  pointer-events: none !important;
  animation: baloon 1s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
  transition: all 0.5s ease;
  z-index: 6;
}
@media all and (max-width: 767px) {
  .globus__baloon {
    font-size: 12px;
    padding: 9px 10px;
  }
}
.globus__baloon:before {
  display: block;
  position: absolute;
  content: '';
  left: -10px;
  top: calc(50% - 5px);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 10px solid #fff;
}
.globus__pin {
  display: block;
  position: relative;
  z-index: 5;
}
.globus__pin.bubbled {
  width: 150px;
  height: 150px;
}
.globus__pin.bubbled .globus__baloon {
  left: 105px;
}
.globus__pin.bubbled:hover .globus__baloon {
  opacity: 0;
}
.globus__pin.bubbled:hover ~ .globus__picWrap {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.globus__pin:before {
  display: block;
  position: absolute;
  content: '';
  width: 60px;
  height: 60px;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  background: #1d8eff;
  border-radius: 100%;
  animation: point 3s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  opacity: 0.2;
  z-index: 1;
}
.globus__pin:after {
  display: block;
  position: absolute;
  content: '';
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  background: #1d8eff;
  border-radius: 100%;
  opacity: 0.2;
  animation: point 2.5s;
  animation-iteration-count: infinite;
  animation-delay: 1.5s;
  z-index: 2;
}
.globus__pin span {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  z-index: 3;
}
.globus__pin span:before {
  display: block;
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  background: #1d8eff;
  border-radius: 100%;
  animation: point 2s;
  opacity: 0.2;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  z-index: 4;
}
.globus__pin span:after {
  display: block;
  position: relative;
  content: '';
  width: 6px;
  height: 6px;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  background: #fff;
  border-radius: 100%;
  z-index: 5;
}
.section-maps {
  padding: 100px 0 50px;
}
@media all and (max-width: 767px) {
  .section-maps {
    padding: 25px 0 20px;
  }
}
.maps-slider {
  padding: 180px 0 160px;
  position: relative;
}
@media all and (min-width: 1760px) {
  .maps-slider {
    padding: 270px 0 240px;
  }
}
@media all and (max-width: 1199px) {
  .maps-slider {
    padding: 50px 0;
  }
}
@media all and (max-width: 1023px) {
  .maps-slider {
    padding: 0;
    padding-top: 12px;
  }
}
.maps-slider__planet {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-width: 900px;
}
@media all and (min-width: 1760px) {
  .maps-slider__planet {
    max-width: 75%;
  }
}
@media all and (max-width: 1279px) {
  .maps-slider__planet {
    max-width: 50%;
  }
}
@media all and (max-width: 1023px) {
  .maps-slider__planet {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    max-width: 100%;
    height: 535px;
    border-radius: 50% 50% 0 0;
    margin: 0 auto;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,0) 50%, #111c3e 50%, #111c3e 100%);
    z-index: 3;
  }
}
@media all and (max-width: 767px) {
  .maps-slider__planet {
    height: 335px;
    width: auto;
    max-width: none;
    margin-left: -20px;
    margin-right: -20px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,0) 50%, #111c3e 50%, #111c3e 100%);
  }
}
.maps-slider__planet .earth-container {
  position: absolute;
  top: -140px;
  bottom: -140px;
  width: 100%;
}
@media all and (max-width: 1023px) {
  .maps-slider__planet .earth-container {
    top: -45px;
    bottom: -45px;
  }
}
@media all and (max-width: 767px) {
  .maps-slider__planet .earth-container {
    top: -55px;
    bottom: -55px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,0) 50%, #111c3e 50%, #111c3e 100%);
  }
}
.maps-slider__box {
  width: 100%;
  max-width: 50%;
  margin-left: 50%;
  position: relative;
  z-index: 5;
}
@media all and (max-width: 1023px) {
  .maps-slider__box {
    max-width: 100%;
    margin-left: 0;
    margin-top: -210px;
  }
}
@media all and (max-width: 767px) {
  .maps-slider__box {
    margin-top: -165px;
  }
}
.mapsCardSingle {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  color: #fff;
  padding: 50px 80px 80px 50px;
  background: #111c3e;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .mapsCardSingle {
    display: block;
    margin-top: 180px;
    padding: 30px 24px 50px;
  }
}
@media all and (max-width: 767px) {
  .mapsCardSingle {
    margin-top: 190px;
    padding: 0;
    padding-top: 14px;
  }
}
.mapsCardSingle__box {
  display: block;
  width: 100%;
  max-width: 470px;
}
@media all and (min-width: 1760px) {
  .mapsCardSingle__box {
    max-width: 705px;
  }
}
@media all and (max-width: 1279px) {
  .mapsCardSingle__box {
    max-width: 100%;
  }
}
.mapsCardSingle__buttons {
  width: 100%;
  margin-bottom: 110px;
}
@media all and (min-width: 1760px) {
  .mapsCardSingle__buttons {
    margin-bottom: 275px;
  }
}
@media all and (max-width: 1023px) {
  .mapsCardSingle__buttons {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 767px) {
  .mapsCardSingle__buttons {
    padding: 0 24px;
  }
}
@media all and (max-width: 767px) {
  .mapsCardSingle__buttons .btn-ui-square-group {
    width: 100%;
  }
}
.mapsCardSingle__buttons .btn-ui-square:last-child {
  margin-left: auto;
}
@media all and (max-width: 1023px) {
  .mapsCardSingle__items {
    padding-right: 200px;
  }
}
@media all and (max-width: 767px) {
  .mapsCardSingle__items {
    padding: 0 24px 55px;
  }
}
.mapsCardSingle__title {
  font-family: 'Playfair Display', serif;
  font-size: 48px;
  line-height: 1.166666666666667;
}
@media all and (max-width: 1023px) {
  .mapsCardSingle__title {
    font-size: 30px;
    line-height: 1.266666666666667;
  }
}
.mapsCardSingle__txt {
  font-size: 14px;
  line-height: 1.714285714285714;
  opacity: 0.5;
  padding-top: 18px;
}
@media all and (max-width: 1023px) {
  .mapsCardSingle__txt {
    font-size: 14px;
    line-height: 1.571428571428571;
    max-width: 100%;
  }
}
.mapsCardSingle__info {
  font-size: 14px;
  padding-top: 28px;
}
@media all and (max-width: 1023px) {
  .mapsCardSingle__info {
    padding-top: 18px;
  }
}
@media all and (max-width: 767px) {
  .mapsCardSingle__info .slash-sep {
    margin: 0 10px;
  }
}
.mapsCardSingle__pictures {
  position: absolute;
  overflow: hidden;
  top: -50px;
  left: 350px;
  margin-bottom: 10px;
  height: 240px;
  width: 172px;
}
@media all and (min-width: 1760px) {
  .mapsCardSingle__pictures {
    height: 360px;
    width: 258px;
    left: 489.99999999999994px;
  }
}
@media all and (max-width: 1399px) {
  .mapsCardSingle__pictures {
    left: auto;
    right: 80px;
  }
}
@media all and (max-width: 1023px) {
  .mapsCardSingle__pictures {
    top: auto;
    bottom: 50px;
    right: 24px;
    margin-bottom: 0;
  }
}
@media all and (max-width: 767px) {
  .mapsCardSingle__pictures {
    position: relative;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 240px;
  }
}
.mapsCardSingle__picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.mapsCardSingle__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mapsCardSingle__picture.is-active {
  z-index: 2;
}
@-moz-keyframes baloon {
  from {
    transform: scale(0) translateY(-50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
}
@-webkit-keyframes baloon {
  from {
    transform: scale(0) translateY(-50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
}
@-o-keyframes baloon {
  from {
    transform: scale(0) translateY(-50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
}
@keyframes baloon {
  from {
    transform: scale(0) translateY(-50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
}
@-moz-keyframes point {
  from {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes point {
  from {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
@-o-keyframes point {
  from {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes point {
  from {
    transform: scale(0);
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
/*
  SECTION NEWS
  ===============================================
  */
.section-news {
  background-color: #111c3e;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/bg-pattern-news.jpg");
  color: #fff;
  padding: 80px 0 120px;
}
@media all and (max-width: 767px) {
  .section-news {
    padding: 45px 0 50px;
  }
}
.section-news__top {
  margin-bottom: 70px;
}
@media all and (max-width: 767px) {
  .section-news__top {
    margin-bottom: 30px;
  }
}
.section-news--news-single {
  padding-bottom: 50px;
}
.news-slider {
  position: relative;
}
.news-slider__left {
  width: 100%;
  max-width: 1140px;
  padding: 0 20px;
  position: absolute;
  top: -12px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (max-width: 767px) {
  .news-slider__left {
    display: none;
  }
}
.news-slider__right {
  margin-left: 25%;
  position: relative;
  z-index: 1;
}
@media all and (max-width: 767px) {
  .news-slider__right {
    margin-left: 20px;
  }
}
.news-slider .swiper-slide {
  max-width: 350px;
  padding-right: 50px;
}
@media all and (min-width: 1760px) {
  .news-slider .swiper-slide {
    max-width: 630px;
  }
}
@media all and (max-width: 767px) {
  .news-slider .swiper-slide {
    padding-right: 20px;
    max-width: 280px;
  }
}
.news-card {
  background-color: #fff;
  color: #111c3e;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.news-card:after {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  bottom: -25px;
  right: -25px;
  background-color: #deb992;
  transition: all 0.25s linear;
}
.news-card__picture {
  height: 255px;
  overflow: hidden;
}
@media all and (min-width: 1760px) {
  .news-card__picture {
    height: 459px;
  }
}
@media all and (max-width: 767px) {
  .news-card__picture {
    height: 220px;
  }
}
.news-card__picture .picture {
  height: 100%;
  transition: all 0.25s linear;
}
.news-card__picture img {
  height: 100%;
  object-fit: cover;
}
.news-card__body {
  padding: 30px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
@media all and (max-width: 767px) {
  .news-card__body {
    padding: 20px 20px 0;
  }
}
.news-card__title {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.185185185185185;
  display: box;
  display: -ms-box;
  display: -webkit-box;
  display: -moz-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -ms-box-orient: vertical;
  min-height: 96px;
  max-height: 96px;
}
.news-card__bottom {
  padding: 35px 0;
  position: relative;
}
@media all and (max-width: 767px) {
  .news-card__bottom {
    padding: 25px 0 30px;
  }
}
.news-card__date {
  color: rgba(17,28,62,0.5);
  font-size: 12px;
}
.news-card__button {
  position: absolute;
  bottom: 20px;
  right: 38px;
  font-size: 12px;
  color: #deb992;
  opacity: 0;
  transition: all 0.25s linear;
}
.news-card:hover:after {
  width: 80px;
  height: 80px;
  bottom: -40px;
  right: -40px;
}
.news-card:hover .news-card__picture .picture {
  transform: scale(1.05);
}
/*
  SECTION INSTAGRAM
  ===============================================
  */
.section-instagram {
  height: 640px;
  overflow: hidden;
  position: relative;
}
@media all and (min-width: 1760px) {
  .section-instagram {
    height: 100vh;
    max-height: 1280px;
  }
}
@media all and (max-width: 767px) {
  .section-instagram {
    height: auto;
    padding-top: 34px;
  }
}
.section-instagram__left {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1140px;
  padding: 0 20px;
  z-index: 1;
}
@media all and (max-width: 767px) {
  .section-instagram__left {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
  }
}
.section-instagram__right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  .section-instagram__right {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
  }
}
.section-instagram__right .section-instagram-pictures {
  margin-left: auto;
  position: relative;
}
@media all and (max-width: 1023px) {
  .section-instagram__right .section-instagram-pictures {
    left: 0;
  }
}
@media all and (max-width: 767px) {
  .section-instagram__right .section-instagram-pictures {
    left: auto;
    position: relative;
    margin-left: 0;
    margin-top: 0;
  }
}
.section-instagram-content {
  max-width: 500px;
}
@media all and (max-width: 1023px) {
  .section-instagram-content {
    max-width: 280px;
  }
}
@media all and (max-width: 767px) {
  .section-instagram-content {
    max-width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
}
.section-instagram-content__icon {
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  .section-instagram-content__icon {
    margin-bottom: 15px;
  }
}
.section-instagram-content__icon .icon {
  fill: #deb992;
  width: 58px;
  height: 58px;
}
@media all and (max-width: 767px) {
  .section-instagram-content__icon .icon {
    margin: 0 auto;
  }
}
.section-instagram-content__title {
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  .section-instagram-content__title {
    margin-bottom: 13px;
  }
}
.section-instagram-content__txt {
  font-size: 14px;
  line-height: 1.714285714285714;
  max-width: 400px;
}
@media all and (max-width: 767px) {
  .section-instagram-content__txt {
    line-height: 1.571428571428571;
  }
}
.section-instagram-content__txt a {
  color: #1d8eff;
}
.section-instagram-content__txt a:hover {
  opacity: 0.5;
}
.section-instagram-content__bottom {
  margin-top: 30px;
}
@media all and (max-width: 767px) {
  .section-instagram-content__bottom {
    margin-top: 20px;
  }
}
.section-instagram-pictures {
  height: 100%;
  overflow: hidden;
  max-width: 50%;
}
@media all and (max-width: 767px) {
  .section-instagram-pictures {
    max-width: 100%;
  }
}
.section-instagram-pictures__rails {
  display: flex;
}
@media all and (max-width: 767px) {
  .section-instagram-pictures__rails {
    display: block;
  }
}
.section-instagram-pictures__rail {
  width: 50%;
  position: relative;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .section-instagram-pictures__rail {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
  }
}
.section-instagram-pictures__rail:first-child {
  padding-right: 12px;
}
@media all and (max-width: 767px) {
  .section-instagram-pictures__rail:first-child {
    padding-right: 20px;
    padding-bottom: 20px;
  }
}
.section-instagram-pictures__rail:last-child {
  padding-left: 12px;
  top: -70px;
}
@media all and (max-width: 767px) {
  .section-instagram-pictures__rail:last-child {
    top: 0;
    left: -40px;
    padding-left: 0;
    padding-right: 20px;
  }
}
.section-instagram-pictures__item {
  display: block;
}
@media all and (max-width: 767px) {
  .section-instagram-pictures__item {
    padding: 0;
    flex: none;
    padding-right: 20px;
  }
}
.section-instagram-pictures__item:not(:last-child) {
  padding-bottom: 24px;
}
@media all and (max-width: 767px) {
  .section-instagram-pictures__item:not(:last-child) {
    padding-bottom: 0;
  }
}
.section-instagram-pictures__pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: 5px 5px 10px rgba(216,216,216,0.5);
}
@media all and (max-width: 767px) {
  .section-instagram-pictures__pic {
    width: 150px;
    height: 210px;
  }
}
/*
  BLOCK HEADER FORM
  ===============================================
  */
.header-form-modal {
  z-index: 20;
  padding-top: 0;
}
.header-form-modal__bottom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
}
.header-form {
  overflow-y: auto;
  position: relative;
}
.header-form__body {
  width: 100%;
  max-width: 523px;
  margin: 0 auto;
}
.header-form__group {
  padding-top: 25px;
}
.header-form__group:not(:last-child) {
  margin-bottom: 15px;
}
.header-form__group .error-message {
  text-align: right;
}
.header-form__input-ta {
  height: 220px;
}
@media all and (max-width: 767px) {
  .header-form__input-ta {
    height: 160px;
  }
}
.header-form__sent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  font-family: 'Playfair Display', serif;
  font-size: 48px;
  line-height: 1.125;
  text-align: center;
}
@media all and (max-width: 767px) {
  .header-form__sent {
    font-size: 28px;
    line-height: 1.214285714285714;
  }
}
.header-form__sent .button {
  margin-top: 45px;
}
.header-form.is-sent .header-form__sent {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}
/*
  BLOCK MODAL
  ===============================================
  */
.modal {
  display: none;
  transition: all 0.25s linear;
}
.modal.is-open {
  display: block;
  animation: modal-show 0.25s linear 1 forwards;
  position: relative;
  z-index: 1000;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__body {
  width: 100%;
  max-width: 640px;
  background-color: #fff;
  transform: scale(0);
}
.modal.is-open .modal__body {
  animation: modal-body-show 0.15s linear 1 forwards;
}
.modal-close-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-close-btn .icon {
  width: 22px;
  height: 22px;
}
.modal-close-btn--white .icon {
  fill: #fff;
}
.modal-fullscreen {
  height: 100%;
  max-width: 100%;
}
.modal-fullscreen .modal-close-btn {
  position: absolute;
  top: 34px;
  right: 50px;
  z-index: 5;
}
@media all and (max-width: 767px) {
  .modal-fullscreen .modal-close-btn {
    top: 1em;
    right: 1em;
  }
}
/* --- WEATHER ------------------------ */
.modal-weather-box {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/placeholder/weather.jpg");
}
/* --- HALF MODAL -------------------- */
.half-modal {
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  background-color: #fff;
  z-index: 100;
  height: 100%;
  color: #111c3e;
  transition: all 0.5s ease-in;
  z-index: 5;
}
@media all and (max-width: 1023px) {
  .half-modal {
    width: 100%;
    padding: 15px 0;
    height: 100vh;
    max-height: 100%;
  }
}
.half-modal.is-active {
  left: 0;
}
.half-modal__close {
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 15px rgba(0,0,0,0.2);
  position: absolute;
  top: 50%;
  right: -24px;
  margin-top: -24px;
}
@media all and (max-width: 1023px) {
  .half-modal__close {
    top: 10px;
    right: 10px;
    margin-top: 0;
    box-shadow: none;
    width: 38px;
    height: 38px;
  }
}
.half-modal__close:before,
.half-modal__close:after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 1px;
  background: #111c3e;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  transition: all 0.25s linear;
}
.half-modal__close:before {
  transform: rotate(45deg);
}
.half-modal__close:after {
  transform: rotate(-45deg);
}
.half-modal__close:hover:before {
  transform: rotate(180deg);
}
.half-modal__close:hover:after {
  transform: rotate(180deg);
}
.half-modal__body {
  padding: 20px 50px;
  margin-right: 30px;
  height: 100%;
  overflow-y: auto;
}
@media all and (max-width: 1023px) {
  .half-modal__body {
    margin-right: 0;
  }
}
@media all and (max-width: 767px) {
  .half-modal__body {
    padding: 0 30px 30px;
  }
}
.half-modal__content {
  width: 100%;
}
.half-modal__content img {
  width: 100%;
  max-width: 100%;
}
.half-modal__content img:not(:last-child) {
  margin-bottom: 30px;
}
.half-modal__content img:not(:first-child) {
  margin-top: 30px;
}
.half-modal__text {
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 25%;
  color: rgba(17,28,62,0.5);
}
@media all and (max-width: 1279px) {
  .half-modal__text {
    padding-left: 0;
  }
}
@media all and (max-width: 1023px) {
  .half-modal__text {
    display: block;
    padding-top: 30px;
  }
}
.half-modal__text_title {
  color: #111c3e;
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  margin-bottom: 1em;
}
/* --- MODAL VIDEO ------------------- */
.modal-video {
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.modal-video .modal-close-btn {
  position: absolute;
  top: 34px;
  right: 52px;
}
@media all and (max-width: 1023px) {
  .modal-video .modal-close-btn {
    top: 20px;
    right: 20px;
  }
}
.modal-video__box {
  width: 100%;
  max-width: 1000px;
}
@-moz-keyframes modal-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes modal-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes modal-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes modal-body-show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes modal-body-show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-o-keyframes modal-body-show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes modal-body-show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
/*
  PAGE GALLERY
  ===============================================
  */
.section-gallery__grid {
  margin: 0 -10px;
}
.section-gallery__item {
  padding: 0 10px 20px;
  opacity: 0;
  width: 44.642857142857146%;
}
@media all and (max-width: 767px) {
  .section-gallery__item {
    padding: 0 5px 10px;
  }
}
.section-gallery__item:nth-child(2n) {
  width: 55.357142857142854%;
}
.section-gallery__item.is-hide {
  display: none;
}
.section-gallery__item.is-active {
  animation: galleryPictureFadeUp 1s ease-in-out 1 forwards;
}
.section-gallery__picture {
  display: block;
}
.section-gallery__picture:hover {
  cursor: zoom-in;
}
.section-gallery__picture img {
  width: 100%;
}
.section-gallery__picture .play-video-icon {
  width: 100px;
  height: 100px;
}
@media all and (max-width: 767px) {
  .section-gallery__picture .play-video-icon {
    width: 70px;
    height: 70px;
  }
}
.section-gallery .play-video-button:hover .play-video-icon__bg {
  fill: #111c3e;
}
@-moz-keyframes galleryPictureFadeUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes galleryPictureFadeUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@-o-keyframes galleryPictureFadeUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes galleryPictureFadeUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
  PAGE ARCHIVE
  ===============================================
  */
.archive-grid {
  margin-top: 40px;
}
.archive-grid__item:not(:last-child) {
  margin-bottom: 60px;
}
.archive-grid__cols {
  margin: 0 -25px;
}
@media all and (max-width: 1023px) {
  .archive-grid__cols {
    margin: 0 -10px;
  }
}
.archive-grid__col {
  padding: 50px 25px 0;
  width: 100%;
  max-width: 50%;
}
@media all and (max-width: 1023px) {
  .archive-grid__col {
    padding: 20px 10px 0;
  }
}
@media all and (max-width: 767px) {
  .archive-grid__col {
    max-width: 100%;
  }
}
/*
  PAGE NEWS
  ===============================================
  */
.news-grid {
  position: relative;
  margin: 115px auto 0;
  padding-bottom: 50px;
  background-color: #111c3e;
}
@media all and (max-width: 1023px) {
  .news-grid {
    padding-bottom: 10px;
  }
}
.news-grid > .container {
  max-width: 1040px;
}
@media all and (min-width: 1760px) {
  .news-grid > .container {
    max-width: 1560px;
  }
}
.news-grid__cols {
  position: relative;
  margin: 0 -25px;
}
@media all and (max-width: 1023px) {
  .news-grid__cols {
    margin: 0 -10px;
  }
}
.news-grid__col {
  width: 100%;
  max-width: 33.33%;
  padding: 0 25px 50px;
}
@media all and (max-width: 1023px) {
  .news-grid__col {
    padding: 0 10px 20px;
    width: 100%;
    max-width: 50%;
  }
}
@media all and (max-width: 767px) {
  .news-grid__col {
    width: 100%;
    max-width: 100%;
  }
}
.news-grid__col:first-child {
  width: 100%;
  max-width: 100%;
  margin-top: -60px;
}
.news-grid__col:first-child .news-card__picture {
  height: 475px;
}
@media all and (min-width: 1760px) {
  .news-grid__col:first-child .news-card__picture {
    height: 712.5px;
  }
}
@media all and (max-width: 767px) {
  .news-grid__col:first-child .news-card__picture {
    height: 220px;
  }
}
.news-grid__col:first-child .news-card__title {
  min-height: auto;
}
/*
  PAGE NEWS SINGLE
  ===============================================
  */
.news-single {
  margin-top: -20px;
}
.news-single__txt {
  font-size: 18px;
  line-height: 1.666666666666667;
}
@media all and (max-width: 1023px) {
  .news-single__txt {
    font-size: 16px;
  }
}
.news-single__txt p,
.news-single__txt ul,
.news-single__txt ol,
.news-single__txt blockquote {
  max-width: 780px;
  padding: 0 20px;
  margin: 0 auto;
}
@media all and (min-width: 1760px) {
  .news-single__txt p,
  .news-single__txt ul,
  .news-single__txt ol,
  .news-single__txt blockquote {
    max-width: 1170px;
  }
}
.news-single__txt p:not(:last-child),
.news-single__txt ul:not(:last-child),
.news-single__txt ol:not(:last-child),
.news-single__txt blockquote:not(:last-child) {
  margin-bottom: 1.6em;
}
.news-single__txt img {
  display: block;
  width: 100%;
  max-width: 1200px;
  height: 600px;
  margin: 0 auto;
  object-fit: cover;
}
@media all and (min-width: 1760px) {
  .news-single__txt img {
    max-width: 1710px;
    height: 900px;
  }
}
@media all and (max-width: 1023px) {
  .news-single__txt img {
    height: auto;
  }
}
.news-single__txt img:not(:last-child) {
  margin-bottom: 80px;
}
@media all and (max-width: 1023px) {
  .news-single__txt img:not(:last-child) {
    margin-bottom: 1.6em;
  }
}
.news-single__txt blockquote {
  display: block;
  padding-top: 2.4em;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 24px;
  background-image: url("../icons/quote.svg");
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.333333333333333;
  text-align: center;
  max-width: 700px;
  margin-top: 2.3em;
}
@media all and (min-width: 1760px) {
  .news-single__txt blockquote {
    max-width: 1050px;
  }
}
@media all and (max-width: 1023px) {
  .news-single__txt blockquote {
    font-size: 20px;
    line-height: 1.5;
  }
}
.news-single__txt blockquote:not(:last-child) {
  margin-bottom: 2.8em;
}
.news-single__txt ul,
.news-single__txt ol {
  list-style: none;
  font-size: 14px;
  line-height: 1.714285714285714;
  margin-top: 2.8em;
}
.news-single__txt ul:not(:last-child),
.news-single__txt ol:not(:last-child) {
  margin-bottom: 2.7em;
}
.news-single__txt ul li,
.news-single__txt ol li {
  padding-left: 1.6em;
  position: relative;
}
.news-single__txt ul li:before,
.news-single__txt ol li:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.news-single__txt ul li:not(:last-child),
.news-single__txt ol li:not(:last-child) {
  margin-bottom: 0.5em;
}
.news-single__txt ul li:before {
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #deb992;
  left: 3px;
  top: 50%;
}
.news-single__txt ol {
  counter-reset: list;
}
.news-single__txt ol li:before {
  font-size: 0.9em;
  line-height: 1;
  counter-increment: list;
  content: counter(list);
  color: #deb992;
}
.news-single .share-ui {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (min-width: 1760px) {
  .news-single .share-ui {
    max-width: 1170px;
  }
}
.news-single .share-ui:before {
  left: 20px;
}
.single-video {
  margin-top: 5.5em;
  margin-bottom: 3.4em;
}
@media all and (max-width: 1023px) {
  .single-video {
    margin-top: 4em;
  }
}
.single-video__link {
  display: block;
  position: relative;
  height: 600px;
}
@media all and (min-width: 1760px) {
  .single-video__link {
    height: 900px;
  }
}
@media all and (max-width: 1023px) {
  .single-video__link {
    height: auto;
  }
}
.single-video__link img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
@media all and (max-width: 1023px) {
  .single-video__link img {
    height: auto;
  }
}
.single-video__link img:not(:last-child) {
  margin-bottom: 0;
}
.single-video__link .play-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.single-video__desc {
  max-width: 600px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.714285714285714;
  color: rgba(17,28,62,0.5);
  text-align: center;
  padding-top: 30px;
}
@media all and (max-width: 1023px) {
  .single-video__desc {
    padding-top: 15px;
  }
}
@media all and (max-width: 767px) {
  .single-video__desc {
    max-width: 100%;
    padding: 15px 15px 0;
  }
}
/*
  SECTION TEAM
  ===============================================
  */
.section-team {
  margin-top: 80px;
  position: relative;
  padding-bottom: 20px;
}
@media all and (max-width: 1023px) {
  .section-team {
    margin-top: 40px;
  }
}
@media all and (max-width: 767px) {
  .section-team {
    padding-bottom: 0;
  }
}
.section-team:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  top: 155px;
  left: 0;
  bottom: 0;
  background-color: #111c3e;
}
.section-team__item {
  position: relative;
  z-index: 1;
}
.section-team__item:not(:last-child) {
  margin-bottom: 25px;
}
.section-team .team-grid {
  color: #fff;
}
.team-grid {
  margin: 0 -20px;
}
@media all and (max-width: 1023px) {
  .team-grid {
    margin: 0 -16px;
  }
}
.team-grid__item {
  max-width: 33.33%;
  padding: 0 20px 60px;
}
@media all and (max-width: 1023px) {
  .team-grid__item {
    padding: 0 16px 60px;
  }
}
@media all and (max-width: 767px) {
  .team-grid__item {
    width: 100%;
    max-width: 100%;
    padding-bottom: 40px;
  }
}
.team-grid--4 {
  margin: 0 -16px;
}
.team-grid--4 .team-grid__item {
  width: 100%;
  max-width: 25%;
  padding-left: 16px;
  padding-right: 16px;
}
@media all and (max-width: 1023px) {
  .team-grid--4 .team-grid__item {
    width: 100%;
    max-width: 33.33%;
  }
}
@media all and (max-width: 767px) {
  .team-grid--4 .team-grid__item {
    width: 100%;
    max-width: 100%;
  }
}
.team-card {
  position: relative;
}
@media all and (max-width: 767px) {
  .team-card {
    max-width: 240px;
    margin: 0 auto;
  }
}
.team-card__picture {
  height: 340px;
  position: relative;
}
@media all and (min-width: 1760px) {
  .team-card__picture {
    height: auto;
  }
}
@media all and (max-width: 1023px) {
  .team-card__picture {
    height: 240px;
  }
}
.team-card__picture img {
  height: 100%;
  object-fit: cover;
}
@media all and (min-width: 1760px) {
  .team-card__picture img {
    width: 100%;
    height: auto;
    object-fit: fill;
  }
}
.team-card__body {
  padding: 32px 0 0;
}
@media all and (max-width: 1023px) {
  .team-card__body {
    padding: 16px 0 0;
  }
}
.team-card__name {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1;
}
@media all and (max-width: 1023px) {
  .team-card__name {
    font-size: 20px;
  }
}
.team-card__position {
  font-size: 14px;
  padding-top: 16px;
}
.team-card--small .team-card__picture {
  height: 255px;
}
@media all and (min-width: 1760px) {
  .team-card--small .team-card__picture {
    height: auto;
  }
}
@media all and (max-width: 1023px) {
  .team-card--small .team-card__picture {
    height: 240px;
  }
}
.team-card__txt {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  color: #111c3e;
  font-size: 14px;
  line-height: 1.428571428571429;
  top: 10px;
  left: 10px;
  height: 100%;
  width: 100%;
  padding: 20px;
  top: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s linear;
}
.team-card__txt p:not(:last-child),
.team-card__txt ol:not(:last-child),
.team-card__txt ul:not(:last-child) {
  margin-bottom: 0.5em;
}
.team-card__txt ol,
.team-card__txt ul {
  margin: 0;
  padding: 0;
  padding-left: 0;
}
.team-card__txt ol li:not(:last-child),
.team-card__txt ul li:not(:last-child) {
  margin-bottom: 0;
}
.team-card:hover .team-card__txt {
  opacity: 1;
  visibility: visible;
  top: 10px;
}
.team-card:hover .team-card__txt:hover ul li:before {
  background-color: #1d8eff;
}
/*
  SECTION PRESS
  ===============================================
  */
.section-press {
  padding-bottom: 65px;
}
@media all and (max-width: 1023px) {
  .section-press {
    padding-bottom: 40px;
  }
}
.section-press .section-title {
  margin-bottom: 30px;
}
.press-slider .swiper-slide {
  padding: 20px 30px;
  width: 100%;
  max-width: 220px;
}
@media all and (min-width: 1760px) {
  .press-slider .swiper-slide {
    max-width: 374px;
  }
}
.press-slide {
  display: block;
  height: 206px;
  box-shadow: 10px 10px 10px rgba(0,0,0,0.15);
}
@media all and (min-width: 1760px) {
  .press-slide {
    height: 412px;
  }
}
.press-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/*
  SECTION FAQ
  ===============================================
  */
@media all and (min-width: 1760px) {
  .section-faq > .container {
    max-width: 1140px;
  }
}
.faq {
  border-top: 1px solid #e2e2e2;
}
.faq__item {
  border-bottom: 1px solid #e2e2e2;
  padding: 30px 0;
}
@media all and (max-width: 767px) {
  .faq__item {
    padding: 15px 0;
  }
}
.faq__q {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.faq__title {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.296296296296296;
  padding-right: 15px;
}
@media all and (max-width: 767px) {
  .faq__title {
    font-size: 18px;
  }
}
.faq__icon {
  flex: none;
  border: 1px solid rgba(17,28,62,0.2);
  transition: all 0.25s linear;
}
.faq__icon .icon {
  fill: #111c3e;
  width: 14px;
  height: 14px;
}
.faq__a {
  max-width: 90%;
  font-size: 14px;
  line-height: 1.714285714285714;
  color: rgba(17,28,62,0.5);
  padding-top: 12px;
}
@media all and (max-width: 1279px) {
  .faq__a {
    max-width: 740px;
  }
}
.faq__q.is-active .faq__icon {
  border-color: #deb992;
  background-color: #deb992;
}
.faq__q.is-active .faq__icon .icon {
  fill: #fff;
  transform: rotateX(180deg);
}
/*
  SECTION PARTNERS
  ===============================================
  */
.partners-grid {
  align-items: center;
  justify-content: center;
}
.partners-grid__item {
  max-width: 33.33%;
  overflow: hidden;
}
.partners-grid__picture {
  padding: 5px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s ease-in-out, opacity 0.2s ease-in-out 0.5s;
}
.partners-grid__picture .img {
  margin: 0 auto;
}
.partners-grid__picture.is-two {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  opacity: 0;
}
.partners-grid__item.is-switch .partners-grid__picture.is-one {
  transform: translateY(100%);
  opacity: 0;
}
.partners-grid__item.is-switch .partners-grid__picture.is-two {
  transform: translateY(0);
  opacity: 1;
}
/*
  SECTION PIC-LEFT
  ===============================================
  */
.section-pic-left {
  padding-bottom: 20px;
}
@media all and (max-width: 767px) {
  .section-pic-left {
    padding-bottom: 0;
  }
}
@media all and (min-width: 1760px) {
  .section-pic-left > .container {
    max-width: 1140px;
  }
}
.pic-left-box {
  display: flex;
  position: relative;
  overflow-y: auto;
  min-height: 540px;
}
@media all and (max-width: 1023px) {
  .pic-left-box {
    padding-left: 0;
    align-items: center;
  }
}
.pic-left-box__left {
  width: 100%;
  max-width: 47.81818181818182%;
  will-change: min-height;
  overflow: hidden;
}
@media all and (max-width: 1023px) {
  .pic-left-box__left {
    display: none;
  }
}
.pic-left-box__left-inner {
  width: 100%;
  transform: translate(0, 0); /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}
.pic-left-box__right {
  flex: none;
  height: 100%;
}
@media all and (max-width: 1023px) {
  .pic-left-box__right {
    width: 100%;
    max-width: 100%;
  }
}
.pic-left-box__right:not(:last-child) {
  padding-bottom: 30px;
}
@media all and (max-width: 1023px) {
  .pic-left-box__left,
  .pic-left-box__right {
    padding-bottom: 30px;
  }
}
.pic-left-box-images {
  width: 71.48288973384031%;
  height: 540px;
  max-height: 540px;
  position: relative;
  margin-top: 30px;
  overflow: hidden;
}
.pic-left-box-images__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pic-left-box-pic {
  height: 100%;
  width: 100%;
}
.pic-left-box-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.25s linear;
}
.pic-left-box-pic img.is-grayscale {
  filter: grayscale(100%);
}
.pic-left-box-pic img.is-grayscale:hover {
  filter: grayscale(0);
}
.pic-left-box-items__one:not(:last-child) {
  margin-bottom: 31px;
}
.pic-left-box-item {
  display: flex;
  align-items: center;
}
.pic-left-box-item__img {
  flex: none;
  width: 133.33333333333334px;
  height: 200px;
  display: none;
}
@media all and (max-width: 1023px) {
  .pic-left-box-item__img {
    display: block;
  }
}
@media all and (max-width: 767px) {
  .pic-left-box-item__img {
    width: 100px;
    height: 150px;
  }
}
.pic-left-box-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media all and (max-width: 1023px) {
  .pic-left-box-item__body {
    padding-left: 30px;
  }
}
@media all and (max-width: 767px) {
  .pic-left-box-item__body {
    padding-left: 15px;
  }
}
.pic-left-box-item__title {
  font-size: 27px;
  line-height: 1.296296296296296;
  font-family: 'Playfair Display', serif;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .pic-left-box-item__title {
    font-size: 20px;
  }
}
.pic-left-box-item__txt {
  padding-top: 15px;
  font-size: 14px;
  line-height: 1.714285714285714;
  color: rgba(17,28,62,0.5);
}
.pic-left-box-item.is-active .pic-left-box-item__title {
  color: #1d8eff;
}
/*
  BLOCK RENT
  ===============================================
  */
.rent-card:not(:last-child) {
  margin-top: 100px;
  margin-bottom: 100px;
}
@media all and (max-width: 767px) {
  .rent-card:not(:last-child) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.rent-card__body {
  position: relative;
  color: #fff;
  padding-bottom: 45px;
}
@media all and (max-width: 767px) {
  .rent-card__body {
    padding-bottom: 0;
  }
}
.rent-card__body:before {
  content: '';
  display: block;
  position: absolute;
  top: 210px;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: #111c3e;
  z-index: -1;
}
@media all and (max-width: 767px) {
  .rent-card__body:before {
    top: 115px;
  }
}
.rent-card .slider-centered {
  margin-bottom: 60px;
}
@media all and (max-width: 1023px) {
  .rent-card .slider-centered {
    margin-bottom: 30px;
  }
}
.rent-card__footer {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 50px;
}
@media all and (max-width: 1023px) {
  .rent-card__footer {
    padding-top: 20px;
    padding-bottom: 0;
  }
}
@media all and (max-width: 767px) {
  .rent-card__footer {
    padding-top: 0;
    padding-bottom: 40px;
  }
}
@media all and (min-width: 1760px) {
  .rent-tabs {
    margin: 0 auto;
    max-width: 1140px;
  }
}
/*
  PAGE KURS
  ===============================================
  */
.kurs-info {
  margin-top: -40px;
  position: relative;
  z-index: 2;
}
.kurs-info__cols {
  background-color: #fff;
  display: flex;
  box-shadow: 0px 2px 100px rgba(0,0,0,0.1);
}
@media all and (max-width: 1023px) {
  .kurs-info__cols {
    flex-wrap: wrap;
  }
}
.kurs-info__col {
  flex-grow: 1;
  position: relative;
}
@media all and (max-width: 1023px) {
  .kurs-info__col {
    width: 100%;
    max-width: 33.33%;
  }
}
@media all and (max-width: 767px) {
  .kurs-info__col {
    width: 100%;
    max-width: 50%;
    border-bottom: 1px solid #f3f3f3;
  }
}
.kurs-info__col:not(:last-child) {
  border-right: 1px solid #f3f3f3;
}
.kurs-info__col--title {
  max-width: 27%;
}
@media all and (max-width: 1023px) {
  .kurs-info__col--title {
    width: 100%;
    max-width: 33.33%;
  }
}
@media all and (max-width: 767px) {
  .kurs-info__col--title {
    width: 100%;
    max-width: 100%;
  }
}
@media all and (max-width: 1023px) {
  .kurs-info__col--title,
  .kurs-info__col--date {
    border-bottom: 1px solid #f3f3f3;
  }
}
.kurs-info__col--title .kurs-info__value {
  white-space: normal;
}
.kurs-info__box {
  padding: 14px 20px 18px;
  height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.kurs-info__box[data-toggle] {
  cursor: pointer;
}
.kurs-info__button {
  padding: 0;
  width: 100%;
  max-width: 225px;
}
@media all and (max-width: 1023px) {
  .kurs-info__button {
    width: 100%;
    max-width: 33.33%;
  }
}
@media all and (max-width: 767px) {
  .kurs-info__button {
    max-width: 100%;
  }
}
.kurs-info__button .button {
  max-width: 100%;
  height: 100%;
}
@media all and (max-width: 767px) {
  .kurs-info__button .button {
    height: 54px;
  }
}
.kurs-info__name {
  color: rgba(17,28,62,0.5);
  font-size: 12px;
  line-height: 2;
}
.kurs-info__value {
  font-size: 14px;
  line-height: 1.714285714285714;
  white-space: nowrap;
}
.kurs-info__value.is-date {
  min-width: 120px;
}
.kurs-info__value.is-persons {
  min-width: 80px;
}
.kurs-info__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 100px rgba(0,0,0,0.2);
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
.kurs-info__dropdown.is-active {
  opacity: 1;
  visibility: visible;
}
.kurs-info__list-button {
  font-size: 14px;
  line-height: 2;
  text-align: left;
  padding: 0 15px;
  white-space: nowrap;
}
.kurs-info__list-button:hover {
  background-color: #f3f3f3;
}
.kurs-info__list-button.is-active {
  background-color: #111c3e;
  color: #fff;
}
.kurs-info__counter {
  display: flex;
  width: 100%;
  padding: 0 15px;
}
.kurs-info__counter .inumber-button {
  flex: none;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #111c3e;
}
.kurs-info__counter .inumber-input {
  height: 40px;
  min-width: 70px;
  text-align: center;
  font-size: 14px;
}
/*
  SECTION REQUIRED
  ===============================================
  */
.section-required .section-title {
  margin-bottom: 10px;
}
.section-required__cols {
  margin: 0 -20px;
}
.section-required__col {
  padding: 40px 20px 0;
}
.section-required__box {
  border: 1px solid #eaeaea;
  padding: 50px;
  height: 100%;
}
@media all and (max-width: 1023px) {
  .section-required__box {
    padding: 25px;
  }
}
.required-box {
  text-align: center;
  padding: 40px 15px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.25s linear;
}
.required-box:hover {
  box-shadow: 0 2.5rem 5rem rgba(0,0,0,0.08);
}
.required-box__icon {
  margin-bottom: 25px;
}
.required-box__icon .icon {
  width: 100px;
  height: 55px;
  fill: #deb992;
  margin: 0 auto;
}
.required-box__content {
  flex-grow: 1;
}
.required-box__title {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.296296296296296;
  margin-bottom: 15px;
}
.required-box__txt {
  font-size: 14px;
  line-height: 1.714285714285714;
  color: rgba(17,28,62,0.5);
  max-width: 460px;
  margin: 0 auto;
}
.required-box__button {
  padding-top: 22px;
}
.include-box {
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .include-box__header {
    margin-bottom: 50px;
  }
}
.include-box__title {
  margin-bottom: 32px;
}
.include-box__title i {
  font-style: normal;
  color: #deb992;
}
.include-box__title .odometer {
  margin-top: -0.3em;
}
.include-box__title .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.include-box__title .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  right: auto;
}
.include-box__buttons {
  display: flex;
  align-items: center;
  margin: 0 -20px;
}
@media all and (max-width: 767px) {
  .include-box__buttons {
    display: block;
    text-align: center;
  }
}
.include-box__button {
  padding: 0 20px;
}
.include-box__button .button {
  width: 200px;
}
@media all and (max-width: 767px) {
  .include-box__button:not(:last-child) {
    margin-bottom: 30px;
  }
}
/*
  PAGE REGATA
  ===============================================
  */
.page-regata .hero-video {
  position: relative;
}
.page-regata .hero-video img,
.page-regata .hero-video .img {
  position: relative;
  z-index: 1;
}
.page-regata .hero-video:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -170px;
  right: 0;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  background-image: url("../images/page-regata-pattern.jpg");
}
.page-regata .section-desc {
  margin-bottom: 85px;
}
.page-regata .section-quote {
  margin: -130px 0 0;
  padding: 140px 0 30px;
}
@media all and (max-width: 1023px) {
  .page-regata .section-quote {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media all and (max-width: 767px) {
  .page-regata .section-quote {
    margin-top: -115px;
    padding-top: 120px;
    padding-bottom: 100px;
  }
}
.page-regata .section-map {
  margin-top: -80px;
}
.page-regata .section-yacht-spec {
  margin-top: -32px;
}
@media all and (max-width: 1023px) {
  .page-regata .section-yacht-spec {
    margin-top: 0;
  }
}
/*
  SECTION DESC
  ===============================================
  */
.section-desc {
  margin-top: 80px;
  margin-bottom: 65px;
}
@media all and (max-width: 767px) {
  .section-desc {
    margin-bottom: 50px;
  }
}
@media all and (min-width: 1760px) {
  .section-desc > .container {
    max-width: 1140px;
  }
}
.section-desc__cols {
  margin: 0;
}
.section-desc__col {
  padding: 0;
}
.section-desc__txt {
  font-size: 14px;
  line-height: 1.714285714285714;
  color: rgba(17,28,62,0.5);
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .section-desc__txt {
    padding-top: 30px;
  }
}
.section-desc__txt h1,
.section-desc__txt h2,
.section-desc__txt h3,
.section-desc__txt h4 {
  font-family: 'Playfair Display', serif;
  font-size: 27px;
  line-height: 1.296296296296296;
  color: #111c3e;
  margin-bottom: 1.1em;
}
@media all and (max-width: 767px) {
  .section-desc__txt h1,
  .section-desc__txt h2,
  .section-desc__txt h3,
  .section-desc__txt h4 {
    font-size: 20px;
  }
}
.section-desc__txt p:not(:last-child) {
  margin-bottom: 1em;
}
.section-desc__txt p:nth-child(n+3) {
  max-height: 0;
  overflow: hidden;
}
.section-desc__txt.is-active p:nth-child(n+3) {
  max-height: 3000px;
  animation: desc-show-down 3s ease-in 1 forwards;
}
.section-desc__txt.is-active .link {
  display: none;
}
@-moz-keyframes desc-show-down {
  from {
    max-height: 0;
  }
  to {
    max-height: 3000px;
  }
}
@-webkit-keyframes desc-show-down {
  from {
    max-height: 0;
  }
  to {
    max-height: 3000px;
  }
}
@-o-keyframes desc-show-down {
  from {
    max-height: 0;
  }
  to {
    max-height: 3000px;
  }
}
@keyframes desc-show-down {
  from {
    max-height: 0;
  }
  to {
    max-height: 3000px;
  }
}
/*
  SECTION TABS
  ===============================================
  */
.section-tabs {
  position: relative;
  z-index: 1;
}
@media all and (min-width: 1760px) {
  .section-tabs > .container {
    max-width: 1140px;
  }
}
.section-tabs .section-title {
  margin-bottom: 40px;
}
.section-tabs .tab-ui {
  padding-top: 40px;
}
@media all and (max-width: 767px) {
  .section-tabs .tab-ui {
    padding-top: 20px;
  }
}
.section-tabs .tab-ui__txt {
  max-width: 550px;
  padding-bottom: 130px;
  line-height: 1.75;
}
@media all and (max-width: 1023px) {
  .section-tabs .tab-ui__txt {
    padding-bottom: 50px;
  }
}
.section-tabs .tab-ui__cols--middle .tab-ui__txt {
  padding-top: 35px;
}
@media all and (max-width: 767px) {
  .section-tabs .tab-ui__cols--middle .tab-ui__txt {
    padding-top: 0;
  }
}
.tabs-box {
  display: flex;
  align-items: center;
  padding-right: 50px;
  padding-top: 40px;
}
@media all and (max-width: 1279px) {
  .tabs-box {
    padding-right: 0;
  }
}
@media all and (max-width: 767px) {
  .tabs-box {
    padding-top: 20px;
    flex-direction: column-reverse;
  }
}
.tabs-box__left {
  flex-grow: 1;
  padding-right: 50px;
}
@media all and (max-width: 1023px) {
  .tabs-box__left {
    padding-right: 30px;
  }
}
@media all and (max-width: 767px) {
  .tabs-box__left {
    padding-right: 0;
  }
}
.tabs-box__right {
  flex: none;
  max-width: 36%;
}
@media all and (max-width: 767px) {
  .tabs-box__right {
    max-width: 50%;
  }
}
.tabs-box__pictures {
  position: relative;
  overflow: hidden;
}
.tabs-box__picture {
  width: 100%;
  position: relative;
}
.tabs-box__picture.is-active {
  z-index: 10;
}
.tabs-box__picture.is-current {
  z-index: 9;
}
.tabs-box__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tabs-box__picture:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
  SECTION QUOTE
  ===============================================
  */
.section-quote {
  background-color: #111c3e;
  color: #fff;
  margin-bottom: 60px;
  margin-top: -70px;
}
@media all and (max-width: 767px) {
  .section-quote {
    margin-top: 0;
  }
}
.section-quote__body {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  padding: 110px 0 160px;
}
@media all and (max-width: 1023px) {
  .section-quote__body {
    padding-bottom: 80px;
  }
}
@media all and (max-width: 767px) {
  .section-quote__body {
    padding: 50px 0;
  }
}
.section-quote__icon {
  margin-bottom: 40px;
}
.section-quote__icon .icon {
  width: 35px;
  height: 24px;
  fill: #deb992;
  margin: 0 auto;
}
.section-quote__txt {
  font-size: 27px;
  line-height: 1.333333333333333;
  font-family: 'Playfair Display', serif;
  margin-bottom: 45px;
}
@media all and (max-width: 767px) {
  .section-quote__txt {
    font-size: 20px;
  }
}
.section-quote__picture {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 20px;
}
.section-quote__picture img {
  height: 100%;
  object-fit: cover;
}
.section-quote__name {
  font-size: 14px;
}
.section-quote__type {
  padding-top: 10px;
  font-size: 12px;
  color: rgba(255,255,255,0.5);
}
.section-quote .slider-dots {
  position: absolute;
  z-index: 2;
  bottom: 113px;
  left: 0;
  width: 100%;
}
@media all and (max-width: 1023px) {
  .section-quote .slider-dots {
    bottom: 40px;
  }
}
@media all and (max-width: 767px) {
  .section-quote .slider-dots {
    bottom: 12.5px;
  }
}
/*
  SECTION MAP
  ===============================================
  */
.section-map {
  margin-bottom: 70px;
  position: relative;
  z-index: 2;
}
.map-box {
  background-color: #ccc;
  width: 100%;
  height: 640px;
  position: relative;
}
@media all and (max-width: 767px) {
  .map-box {
    height: 420px;
  }
}
.map-box__container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.map-box__controls {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 767px) {
  .map-box__controls {
    top: 10px;
    right: 10px;
    transform: none;
  }
}
.map-box__control {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #111c3e;
  color: #fff;
  position: relative;
  font-family: 'Playfair Display', serif;
  line-height: 40px;
  font-size: 27px;
}
.map-box__control:first-child {
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  .map-box__control:first-child {
    margin-bottom: 10px;
  }
}
.map-box__control:before,
.map-box__control:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.map-box__control.is-zoom-in:before {
  width: 14px;
  height: 1px;
}
.map-box__control.is-zoom-in:after {
  width: 1px;
  height: 14px;
}
.map-box__control.is-zoom-out:before {
  width: 14px;
  height: 1px;
}
.map-box__control.is-zoom-out:after {
  display: none;
}
.map-box__control:hover {
  background-color: #deb992;
}
.map-box-popup .gm-style-iw-c {
  background: none;
  color: #111c3e;
  font-size: 14px;
  padding: 0 !important;
  border-radius: 0;
  box-shadow: none;
}
.map-box-popup .gm-style-iw-d {
  overflow: hidden !important;
}
.map-box-popup .gm-ui-hover-effect {
  display: none !important;
}
.map-box-popup::after {
  display: none;
}
.map-box-popup__txt {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
}
.map-box-popup-length .gm-style-iw-c {
  background: none;
  padding: 0 !important;
  box-shadow: none;
  font-size: 12px;
  color: rgba(17,28,62,0.5);
  text-align: center;
}
.map-box-popup-length .gm-style-iw-d {
  overflow: hidden !important;
}
.map-box-popup-length .gm-ui-hover-effect {
  display: none !important;
}
.map-box-popup-length::after {
  display: none;
}
.map-box-popup-length__txt {
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  color: rgba(17,28,62,0.5);
}
/*
  SECTION YACHT-SPEC
  ===============================================
  */
.section-yacht-spec {
  height: 100vh;
  background-color: #111c3e;
  color: #fff;
  padding: 85px 0;
  margin-bottom: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media all and (max-width: 1279px) {
  .section-yacht-spec {
    padding: 50px 0;
    height: auto;
  }
}
.section-yacht-spec__box {
  position: relative;
  transition: all 0.5s ease-in;
}
.section-yacht-spec__box.is-active {
  transform: translateX(40%);
}
@media all and (max-width: 1279px) {
  .section-yacht-spec__box.is-active {
    transform: translateX(50%);
  }
}
@media all and (max-width: 1023px) {
  .section-yacht-spec__box.is-active {
    transform: translateX(100%);
  }
}
.yachtspec-baloon__group {
  opacity: 0;
  transition: all 0.3s ease;
}
.yachtspec-baloon:hover .yachtspec-baloon__group {
  opacity: 1;
}
.yachtspec-picture {
  height: 570px;
  max-width: 100%;
  width: 100%;
}
@media all and (min-width: 1760px) {
  .yachtspec-picture {
    height: 855px;
    max-height: 90vh;
  }
}
.yachtspec-picture image {
  max-width: 100%;
}
.yachtspec-picture path {
  transition: all 0.25s linear;
}
.yachtspec-picture .circle {
  fill: #deb992;
  opacity: 0.2;
}
.yachtspec-picture .dot {
  fill: #deb992;
}
.yachtspec-picture .group:hover .circle,
.yachtspec-picture .group:hover .dot {
  fill: #fff;
}
.yachtspec {
  align-items: center;
}
.yachtspec__header {
  font-size: 14px;
  line-height: 1;
}
.yachtspec__title {
  margin: 20px 0 32px;
}
.yachtspec__list {
  font-size: 14px;
  line-height: 1.428571428571429;
}
.yachtspec__list p:not(:last-child) {
  margin-bottom: 1.3em;
}
.yachtspec__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style: disc;
  padding-left: 1.5em;
  color: rgba(255,255,255,0.5);
  font-size: 14px;
  line-height: 1.714285714285714;
}
.yachtspec__list ul li:not(:last-child) {
  margin-bottom: 0.6em;
}
/*
 * --- SECTION NEXT -----------------------------
 */
.section-next {
  text-align: center;
  margin-bottom: 22px;
}
.section-next:not(:first-child) {
  margin-top: 120px;
}
@media all and (max-width: 767px) {
  .section-next:not(:first-child) {
    margin-top: 60px;
  }
}
.section-next__type {
  color: rgba(17,28,62,0.5);
  font-size: 14px;
}
.section-next__page {
  margin: 30px 0 40px;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .section-next__page {
    margin-bottom: 30px;
  }
}
.section-next__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin: 0 auto;
}
@media all and (max-width: 767px) {
  .section-next__icon {
    width: 46px;
    height: 46px;
  }
}
.section-next__icon .icon {
  width: 100%;
  height: 100%;
  fill: #deb992;
  transition: all 0.25s linear;
}
.section-next__link {
  display: block;
}
.section-next__link:hover .icon {
  fill: #1d8eff;
}
.section-next__link:hover .section-next__page {
  color: #1d8eff;
}
/*
 * --- PAGE 404 ---------------------------------
 */
.page404 {
  background-color: #000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.page404__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-image: url("../images/bg-page404.jpg");
  opacity: 0.6;
  z-index: 1;
}
.page404__body {
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 14px;
  line-height: 1.714285714285714;
  text-align: center;
}
.page404__title {
  font-size: 124px;
  color: #fff;
}
.page404__footer {
  margin-top: 40px;
}
