/*!
  PAGE NEWS
  ===============================================
  */
.news-grid
  position: relative
  margin: (60px + 55) auto 0
  padding-bottom: 50px
  background-color: colorText
  @media pad
    padding-bottom: 10px

  & > .container
    max-width: 1040px
    @media wide
      max-width: (1040px * 1.5)

  &__cols
    position: relative
    margin: 0 -25px
    @media pad
      margin: 0 -10px

  &__col
    col(1,3)
    padding: 0 25px 50px
    @media pad
      padding: 0 10px 20px
      col(1,2)
    @media phone
      col(1,1)

    &:first-child
      col(1,1)
      margin-top: -60px

      .news-card__picture
        height: 475px
        @media wide
          height: (475px * 1.5)
        @media phone
          height: 220px

      .news-card__title
        min-height: auto
