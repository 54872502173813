/*!
  PAGE NEWS SINGLE
  ===============================================
  */
.news-single
  margin-top: -20px

  &__txt
    text(18,30)
    @media pad
      font-size: 16px

    p, ul, ol, blockquote
      max-width: (740px + 40)
      padding: 0 20px
      margin: 0 auto
      @media wide
        max-width: ((740px + 40) * 1.5)

      &:not(:last-child)
        margin-bottom: 1.6em

    img
      display: block
      width: 100%
      max-width: 1200px
      height: 600px
      margin: 0 auto
      object-fit: cover
      @media wide
        max-width: (containerWidth * 1.5)
        height: (600px * 1.5)
      @media pad
        height: auto

      &:not(:last-child)
        margin-bottom: 80px
        @media pad
          margin-bottom: 1.6em

    blockquote
      display: block
      padding-top: 2.4em
      bg(top center, auto 24px)
      background-image: url('../icons/quote.svg')
      font-family: fontPlayfair
      text(27,36)
      text-align: center
      max-width: 700px
      margin-top: 2.3em
      @media wide
        max-width: (700px * 1.5)
      @media pad
        font-size: 20px
        line-height: 1.5

      &:not(:last-child)
        margin-bottom: 2.8em

    ul, ol
      list-style: none
      text(14,24)
      margin-top: 2.8em

      &:not(:last-child)
        margin-bottom: 2.7em

      li
        padding-left: 1.6em
        position: relative

        &:before
          pseudo()
          left: 0
          top: 50%
          transform: translateY(-50%)

        &:not(:last-child)
          margin-bottom: 0.5em

    ul li
      &:before
        pseudo()
        size(4px,4px)
        border-radius: 100%
        background-color: colorSand
        left: 3px
        top: 50%

    ol
      counter-reset: list

      li
        &:before
          font-size: 0.9em
          line-height: 1
          counter-increment: list
          content: counter(list)
          color: colorSand

  .share-ui
    max-width: (740px + 40)
    margin-left: auto
    margin-right: auto
    padding-left: 20px
    padding-right: 20px
    @media wide
      max-width: ((740px + 40) * 1.5)

    &:before
      left: 20px


.single-video
  margin-top: 5.5em
  margin-bottom: 3.4em
  @media pad
    margin-top: 4em

  &__link
    display: block
    position: relative
    height: 600px
    @media wide
      height: (600px * 1.5)
    @media pad
      height: auto

    img
      height: 100%
      max-width: 100%
      object-fit: cover
      @media pad
        height: auto

      &:not(:last-child)
        margin-bottom: 0

    .play-video-icon
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)

  &__desc
    max-width: 600px
    margin: 0 auto
    text(14,24)
    color: rgba(colorText,0.5)
    text-align: center
    padding-top: 30px
    @media pad
      padding-top: 15px
    @media phone
      max-width: 100%
      padding: 15px 15px 0
