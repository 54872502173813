/*!
  SECTION DESC
  ===============================================
  */
.section-desc
  margin-top: 80px
  margin-bottom: 65px
  @media phone
    margin-bottom: 50px

  & > .container
    @media wide
      max-width: containerWidth

  &__cols
    margin: 0

  &__col
    padding: 0

  &__txt
    text(14,24)
    color: rgba(colorText,0.5)
    transit()
    @media phone
      padding-top: 30px

    h1, h2, h3, h4
      font-family: fontPlayfair
      text(27,35)
      color: colorText
      margin-bottom: 1.1em
      @media phone
        font-size: 20px

    p:not(:last-child)
      margin-bottom: 1em

    p:nth-child(n+3)
      max-height: 0
      overflow: hidden

    &.is-active
      p:nth-child(n+3)
        max-height: 3000px
        animation: desc-show-down 3s ease-in 1 forwards

      .link
        display: none


@keyframes desc-show-down
  from
    max-height: 0
  to
    max-height: 3000px
