/*!
  HEADER
  ===============================================
  */
.header
  padding: 30px 0
  position: relative
  z-index: 10
  @media phone
    padding: 20px 0

  &__body
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    z-index: 12

  &.is-overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    z-index: 15


.header-logo
  display: block
  max-width: 128px
  max-height: 30px

  &__item
    &--light
      display: none

    &--dark
      display: block


.header-buttons
  display: flex
  align-items: center
  justify-content: flex-end

  &__item
    display: flex
    align-items: center

    &:not(:last-child)
      margin-right: 40px


.header-search-button
  .icon
    size(18px,18px)
    fill: colorText


.header-burger-button
  position: relative
  width: 30px
  height: 30px

  &:before, &:after
    pseudo()
    width: 100%
    height: 1px
    background-color: colorText
    left: 0
    transit()

  &:before
    top: 10px

  &:after
    bottom: 10px

  &.is-active
    &:before, &:after
      top: 50%
      bottom: auto

    &:before
      transform: rotate(45deg)
    &:after
      transform: rotate(-45deg)


.header.is-light .header-logo
  &__item
    display: none
    &--light
      display: block

.header.is-light
  .header-burger-button:before,
  .header-burger-button:after
    background-color: #fff

  .header-search-button
    .icon
      fill: #fff


.header
  &.is-active-light
    .header-logo
      &__item
        display: none
        &--light
          display: block

    .header-burger-button:before,
    .header-burger-button:after
      background-color: #fff

  &.is-active-dark
    .header-logo
      &__item
        display: none
        &--dark
          display: block

    .header-burger-button:before,
    .header-burger-button:after
      background-color: colorText

  &.is-active-dark,
  &.is-active-light
    .header-search-button
      display: none


/*! --- HEADER MODAL ------------------ */
.header-modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: #fff
  bg(bottom center, 100% auto)
  background-image: url('../images/bg-header-active.png')
  overflow: hidden
  z-index: 10
  padding-top: 60px
  padding-bottom: 15px

  &__body
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
