/*!
  BLOCK MODAL
  ===============================================
  */
.modal
  display: none
  transit()

  &.is-open
    display: block
    animation: modal-show 0.25s linear 1 forwards
    position: relative
    z-index: 1000

  &__overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(#000,0.8)
    display: flex
    align-items: center
    justify-content: center

  &__body
    width: 100%
    max-width: 640px
    background-color: #fff
    transform: scale(0)

  &.is-open &__body
    animation: modal-body-show 0.15s linear 1 forwards


.modal-close-btn
  size(32px,32px)
  display: flex
  align-items: center
  justify-content: center

  .icon
    size(22px,22px)

  &--white
    .icon
      fill: #fff


@keyframes modal-show
  from
    opacity: 0
  to
    opacity: 1

@keyframes modal-body-show
  from
    transform: scale(0)
  to
    transform: scale(1)


.modal-fullscreen
  height: 100%
  max-width: 100%

  .modal-close-btn
    position: absolute
    top: 34px
    right: 50px
    z-index: 5
    @media phone
      top: 1em
      right: 1em


/*! --- WEATHER ------------------------ */
.modal-weather-box
  width: 100%
  height: 100%
  background-color: #ccc
  bg(center,cover)
  background-image: url('../images/placeholder/weather.jpg')


/*! --- HALF MODAL -------------------- */
.half-modal
  position: absolute
  top: 0
  left: -100%
  width: 50%
  background-color: #fff
  z-index: 100
  height: 100%
  color: colorText
  transition: all 0.5s ease-in
  z-index: 5
  @media pad
    width: 100%
    padding: 15px 0
    height: 100vh
    max-height: 100%

  &.is-active
    left: 0

  &__close
    size(48px,48px)
    background-color: #fff
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2)
    position: absolute
    top: 50%
    right: -(48px/2)
    margin-top: -(48px/2)
    @media pad
      top: 10px
      right: 10px
      margin-top: 0
      box-shadow: none
      size(38px,38px)

    &:before,
    &:after
      pseudo()
      width: 24px
      height: 1px
      background: colorText
      top: 50%
      left: 50%
      margin-left: -12px
      transit()

    &:before
      transform: rotate(45deg)

    &:after
      transform: rotate(-45deg)

    &:hover
      &:before
        transform: rotate(180deg)
      &:after
        transform: rotate(180deg)

  &__body
    padding: 20px 50px
    margin-right: 30px
    height: 100%
    overflow-y: auto
    @media pad
      margin-right: 0
    @media phone
      padding: 0 30px 30px

  &__content
    // height: 100%
    width: 100%
    img
      width: 100%
      max-width: 100%
      &:not(:last-child)
        margin-bottom: 30px

      &:not(:first-child)
        margin-top: 30px

  &__text
    height: 100%
    margin: 0 auto
    display: flex
    justify-content: center
    flex-direction: column
    padding-left: (100%/4)
    color: rgba(colorText,0.5)
    @media tablet
      padding-left: 0
    @media pad
      display: block
      padding-top: 30px

    &_title
      color: colorText
      font-family: fontPlayfair
      font-size: 27px
      margin-bottom: 1em


/*! --- MODAL VIDEO ------------------- */
.modal-video
  max-width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 20px

  .modal-close-btn
    position: absolute
    top: 34px
    right: 52px
    @media pad
      top: 20px
      right: 20px

  &__box
    width: 100%
    max-width: 1000px
