/*!
  SECTION REQUIRED
  ===============================================
  */
.section-required
  .section-title
    margin-bottom: 10px

  &__cols
    margin: 0 -20px

  &__col
    padding: 40px 20px 0

  &__box
    border: 1px solid #EAEAEA
    padding: 50px
    height: 100%
    @media pad
      padding: 25px


.required-box
  text-align: center
  padding: 40px 15px 50px
  display: flex
  flex-direction: column
  justify-content: space-between
  transit()

  &:hover
    box-shadow: 0 2.5rem 5rem rgba(0,0,0,.08)

  &__icon
    margin-bottom: 25px

    .icon
      size(100px,55px)
      fill: colorSand
      margin: 0 auto

  &__content
    flex-grow: 1

  &__title
    font-family: fontPlayfair
    text(27,35)
    margin-bottom: 15px

  &__txt
    text(14,24)
    color: rgba(colorText,0.5)
    max-width: 460px
    margin: 0 auto

  &__button
    padding-top: 22px


.include-box
  transit()

  // &:hover
  //   box-shadow: 0 2.5rem 5rem rgba(0,0,0,.08)

  &__header
    @media pad
      margin-bottom: 50px

  &__title
    margin-bottom: 32px

    i
      font-style: normal
      color: colorSand

    .odometer
      margin-top: -0.3em

      &.odometer-auto-theme .odometer-digit .odometer-digit-inner,
      &.odometer-theme-default .odometer-digit .odometer-digit-inner
        right: auto

  &__buttons
    display: flex
    align-items: center
    margin: 0 -20px
    @media phone
      display: block
      text-align: center

  &__button
    padding: 0 20px

    .button
      width: 200px

    &:not(:last-child)
      @media phone
        margin-bottom: 30px
