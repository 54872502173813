/*!
  PAGE KURS
  ===============================================
  */
.kurs-info
  margin-top: -40px
  position: relative
  z-index: 2

  &__cols
    background-color: #fff
    display: flex
    box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.1)
    @media pad
      flex-wrap: wrap

  &__col
    flex-grow: 1
    position: relative
    @media pad
      col(1,3)
    @media phone
      col(1,2)
      border-bottom: 1px solid #F3F3F3

    &:not(:last-child)
      border-right: 1px solid #F3F3F3

    &--title
      max-width: 27%
      @media pad
        col(1,3)
      @media phone
        col(1,1)

    &--title,
    &--date
      @media pad
        border-bottom: 1px solid #F3F3F3

  &__col--title &__value
    white-space: normal

  &__box
    padding: 14px 20px 18px
    height: 100%
    position: relative
    z-index: 2
    background-color: #fff

    &[data-toggle]
      cursor: pointer

  &__button
    padding: 0
    width: 100%
    max-width: 225px
    @media pad
      col(1,3)
    @media phone
      cl(1,1)

    .button
      max-width: 100%
      height: 100%
      @media phone
        height: 54px

  &__name
    color: rgba(colorText,0.5)
    text(12,24)

  &__value
    text(14,24)
    white-space: nowrap

    &.is-date
      min-width: 120px

    &.is-persons
      min-width: 80px

  &__dropdown
    position: absolute
    top: 100%
    left: 0
    min-width: 100%
    background-color: #fff
    box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.2)
    padding: 15px 0
    opacity: 0
    visibility: hidden
    z-index: 1

    &.is-active
      opacity: 1
      visibility: visible

  &__list
    &-button
      text(14,28)
      text-align: left
      padding: 0 15px
      white-space: nowrap

      &:hover
        background-color: #F3F3F3

      &.is-active
        background-color: colorText
        color: #fff

  &__counter
    display: flex
    width: 100%
    padding: 0 15px

    .inumber-button
      flex: none
      size(40px,40px)
      color: #fff
      background-color: colorText

    .inumber-input
      height: 40px
      min-width: 70px
      text-align: center
      font-size: 14px
