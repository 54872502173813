/*!
  PAGE GALLERY
  ===============================================
  */
.section-gallery
  &__grid
    margin: 0 -10px

  &__item
    padding: 0 10px 20px
    opacity: 0
    width: ((500 * 100%) / 1120)
    @media phone
      padding: 0 5px 10px

    &:nth-child(2n)
      width: ((620 * 100%) / 1120)

    &.is-hide
      display: none

    &.is-active
      animation: galleryPictureFadeUp 1s ease-in-out 1 forwards

  &__picture
    display: block

    &:hover
      cursor: zoom-in

    img
      width: 100%

    .play-video-icon
      size(100px,100px)
      @media phone
        size(70px,70px)

  .play-video-button:hover .play-video-icon__bg
    fill: colorText


@keyframes galleryPictureFadeUp
  from
    opacity: 0
    transform: translateY(15px)
  to
    opacity: 1
    transform: translateY(0)
