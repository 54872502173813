/*!
  PAGE ARCHIVE
  ===============================================
  */
.archive-grid
  margin-top: 40px

  &__item
    &:not(:last-child)
      margin-bottom: 60px

  &__cols
    margin: 0 -25px
    @media pad
      margin: 0 -10px

  &__col
    padding: 50px 25px 0
    col(1,2)
    @media pad
      padding: 20px 10px 0
    @media phone
      cl(1,1)
