/*!
  BLOCK RENT
  ===============================================
  */
.rent-card
  &:not(:last-child)
    margin-top: 100px
    margin-bottom: 100px
    @media phone
      margin-top: 50px
      margin-bottom: 50px

  &__body
    position: relative
    color: #fff
    padding-bottom: 45px
    @media phone
      padding-bottom: 0

    &:before
      pseudo()
      top: 210px
      left: 0
      width: 100%
      bottom: 0
      background-color: colorText
      z-index: -1
      @media phone
        top: (230px / 2)

  .slider-centered
    margin-bottom: 60px
    @media pad
      margin-bottom: 30px

  &__footer
    text-align: center
    padding-top: 40px
    padding-bottom: 50px
    @media pad
      padding-top: 20px
      padding-bottom: 0
    @media phone
      padding-top: 0
      padding-bottom: 40px


.rent-tabs
  @media wide
    margin: 0 auto
    max-width: containerWidth
