/*!
  SECTION PIC-LEFT
  ===============================================
  */
.section-pic-left
  padding-bottom: 20px
  @media phone
    padding-bottom: 0

  & > .container
    @media wide
      max-width: containerWidth


.pic-left-box
  display: flex
  position: relative
  overflow-y: auto
  min-height: 540px
  @media pad
    padding-left: 0
    align-items: center

  &__left
    width: 100%
    max-width: ((526 * 100%) / 1100)
    will-change: min-height
    overflow: hidden
    @media pad
      display: none

    &-inner
      width: 100%
      transform: translate(0, 0); /* For browsers don't support translate3d. */
      transform: translate3d(0, 0, 0);
      will-change: position, transform;

  &__right
    flex: none
    height: 100%
    @media pad
      col(1,1)

    &:not(:last-child)
      padding-bottom: 30px

  &__left,
  &__right
    @media pad
      padding-bottom: 30px


.pic-left-box-images
  width: ((376 * 100%) / 526)
  height: 540px
  max-height: 540px
  position: relative
  margin-top: 30px
  overflow: hidden

  &__img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%


.pic-left-box-pic
  height: 100%
  width: 100%

  img
    size(100%,100%)
    object-fit: cover
    transit()

    &.is-grayscale
      filter: grayscale(100%)

      &:hover
        filter: grayscale(0)


.pic-left-box-items
  &__one
    &:not(:last-child)
      margin-bottom: 31px


.pic-left-box-item
  display: flex
  align-items: center

  &__img
    flex: none
    width: ((200px / 3) * 2)
    height: 200px
    display: none
    @media pad
      display: block
    @media phone
      width: ((150px / 3) * 2)
      height: 150px

    img
      size(100%,100%)
      object-fit: cover

  &__body
    @media pad
      padding-left: 30px
    @media phone
      padding-left: 15px

  &__title
    text(27,35)
    font-family: fontPlayfair
    transit()
    @media phone
      font-size: 20px

  &__txt
    padding-top: 15px
    text(14,24)
    color: rgba(colorText,0.5)

  &.is-active &__title
    color: colorBlue
