/*!
  SECTION ARCHIVE
  ===============================================
  */
.section-archive
  padding: 50px 0 30px
  background: linear-gradient(to bottom, rgba(#fff,0) 76%, rgba(#fff,0) 76%, colorText 76%, colorText 100%)
  @media phone
    padding: 25px 0 0


.archiveSlide
  width: 100%
  height: 100%
  position: relative

  .archiveCard
    width: 100%
    height: 100%


.archiveCard
  display: flex
  align-items: flex-end
  position: relative
  height: 420px
  overflow: hidden
  @media wide
    height: (420px * 1.2)
  @media pad
    height: 320px
  @media phone
      height: 230px

  &__picture
    width: 100%
    height: 100%
    background-color: #000
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    transit()

    img
      height: 100%
      object-fit: cover
      filter: grayscale(100%)
      opacity: 0.5
      transit()

  &__body
    position: relative
    width: 100%
    z-index: 2
    color: #fff
    padding: 30px 30px 35px
    transit()
    @media pad
      padding: 20px

  &__title
    font-family: fontPlayfair
    font-size: 27px
    @media phone
      font-size: 18px

  &__info
    font-size: 12px
    padding-top: 15px
    @media phone
      padding-top: 10px

  &:hover &__body
    opacity: 1

  &:hover &__picture
    transform: scale(1.05)

    img
      opacity: 1

  &.is-hover-colored:hover &__picture
    img
      filter: grayscale(0)
