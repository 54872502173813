/*!
  SECTION TEAM
  ===============================================
  */
.section-team
  margin-top: 80px
  position: relative
  padding-bottom: 20px
  @media pad
    margin-top: 40px
  @media phone
    padding-bottom: 0

  &:before
    pseudo()
    width: 100%
    top: 155px
    left: 0
    bottom: 0
    background-color: colorText

  &__item
    position: relative
    z-index: 1

    &:not(:last-child)
      margin-bottom: 25px

  .team-grid
    color: #fff


.team-grid
  margin: 0 -20px
  @media pad
    margin: 0 -16px

  &__item
    cl(1,3)
    padding: 0 20px 60px
    @media pad
      padding: 0 16px 60px
    @media phone
      col(1,1)
      padding-bottom: 40px

  &--4
    margin: 0 -16px

  &--4 &__item
    col(1,4)
    padding-left: 16px
    padding-right: 16px
    @media pad
      col(1,3)
    @media phone
      col(1,1)


.team-card
  position: relative
  @media phone
    max-width: 240px
    margin: 0 auto

  &__picture
    height: 340px
    position: relative
    @media wide
      height: auto
    @media pad
      height: 240px

    img
      height: 100%
      object-fit: cover
      @media wide
        width: 100%
        height: auto
        object-fit: fill

  &__body
    padding: 32px 0 0
    @media pad
      padding: 16px 0 0

  &__name
    font-family: fontPlayfair
    font-size: 27px
    line-height: 1
    @media pad
      font-size: 20px

  &__position
    font-size: 14px
    padding-top: 16px

  &--small &
    &__picture
      height: 255px
      @media wide
        height: auto
      @media pad
        height: 240px

  &__txt
    position: absolute
    z-index: 1
    background-color: #fff
    color: colorText
    text(14,20)
    top: 10px
    left: 10px
    height: 100%
    width: 100%
    padding: 20px
    top: 30px
    opacity: 0
    visibility: hidden
    transit()

    p, ol, ul
      &:not(:last-child)
        margin-bottom: 0.5em

    ol, ul
      margin: 0
      padding: 0
      padding-left: 0

      li:not(:last-child)
        margin-bottom: 0

  &:hover &__txt
    opacity: 1
    visibility: visible
    top: 10px

    &:hover
      ul
        li:before
          background-color: colorBlue
