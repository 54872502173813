/*!
  SECTION YACHT-SPEC
  ===============================================
  */
.section-yacht-spec
  height: 100vh
  background-color: colorText
  color: #fff
  padding: 85px 0
  margin-bottom: 80px
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  @media tablet
    padding: 50px 0
    height: auto

  &__box
    position: relative
    transition: all 0.5s ease-in

    &.is-active
      transform: translateX(40%)
      @media tablet
        transform: translateX(50%)
      @media pad
        transform: translateX(100%)


.yachtspec-baloon
  &__group
    opacity: 0
    transition: all .3s ease

  &:hover &__group
    opacity: 1


.yachtspec-picture
  height: 570px
  max-width: 100%
  width: 100%
  @media wide
    height: (570px * 1.5)
    max-height: 90vh

  image
    max-width: 100%

  path
    transit()

  .circle
    fill: colorSand
    opacity: 0.2

  .dot
    fill: colorSand

  .group:hover
    .circle, .dot
      fill: #fff


.yachtspec
  align-items: center

  &__header
    text(14,14)

  &__title
    margin: 20px 0 32px

  &__list
    text(14,20)

    p:not(:last-child)
      margin-bottom: 1.3em

    ul
      resetul()
      list-style: disc
      padding-left: 1.5em
      color: rgba(#fff,0.5)
      text(14,24)

      li:not(:last-child)
        margin-bottom: 0.6em
