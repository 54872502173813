/*!
  BASE
  ===============================================
  */
body, html
  border: 0
  width: 100%
  overflow-x: hidden
  scroll-behavior: smooth


html
  font-size: 16px
  cursor: none !important

  &.is-no-scroll
    overflow: hidden

  *
    cursor: none !important
    &:hover
      cursor: none !important


body
  font-family: fontNoto
  color: colorText
  font-weight: normal
  background-color: #fff
  line-height: 1
  overflow: hidden
  display: flex
  min-height: 100vh
  position: relative

  &.is-disabled-scroll
    overflow: hidden
    max-height: 100vh


.browsehappy
  display: block
  margin: 0
  padding: 30px
  text-align: center
  background: #e55454
  color: #fff
  font-size: 18px
  line-height: 1.4

  a,
  a:hover
    color: #fff
    text-decoration: underline


.container
  width: 100%
  max-width: containerWidth
  padding: 0 20px
  margin: 0 auto
  @media wide
    max-width: (1140px * 1.5)


/*!
  FOOTER TO BOTTOM
  ===============================================
  */
.wrapper
  flex: 1 1 auto
  width: 100%
  min-height: 1vh
  display: flex
  flex-flow: column nowrap


.main
  flex: 1 1 auto
  min-height: 1vh


.header, .footer
  flex-shrink: 0


/*!
  UI ELEMENTS
  ===============================================
  */
a
  font-size: inherit
  font-family: inherit
  text-decoration: none
  color: inherit
  transit()


strong, b
  font-weight: bold


button, input, select, textarea
  font-family: inherit
  font-size: inherit
  color: inherit
  width: auto
  outline: none
  line-height: 1
  border: 0
  padding: 0
  margin: 0
  background-color: rgba(255,255,255,0)
  transit()

  &::placeholder
    font-family: inherit
    color: inherit
    font-size: inherit


button
  cursor: none !important
  &:hover
    cursor: none !important


button,
button[type="submit"],
input[type="submit"]
  cursor: pointer


input[type="search"],
input[type="number"]
  -webkit-appearance: textfield


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0


button, input, textarea
  &:disabled
  &.is-disabled
    cursor: not-allowed


textarea
  width: 100%
  resize: none


fieldset
  border: none
  margin: 0
  padding: 0


select.select,
.select select
    appearance: none

    &::-ms-expand
      display: none


img:not([alt])
  filter: none


#baguetteBox-overlay
  .full-image
    .img
      max-height: 90%


h1, h2, h3, h4, h5, h6
  font-weight: normal


/*!
  GRID
  ===============================================
  */
.rw
  display: flex
  flex-direction: row
  flex-wrap: wrap
  list-style: none
  margin: 0 (-(gridPadding))

.cl
  width: 100%
  padding: 0 gridPadding

cl(num,columns)
  max-width: floor( ((100% / columns)* num), 2 )

col(num, columns)
  width: 100%
  max-width: floor( ((100% / columns)* num), 2 )

grid($columns, $prefix = '')
  for $col in 1..$columns
    .cl{$prefix}-{$col}
      max-width floor( ((100% / $columns) * $col), 6 )

grid(12)

@media note
  grid(12, '-nt')

@media laptop
  grid(12, '-lp')

@media tablet
  grid(12, '-tb')

@media pad
  grid(12, '-pd')

@media phone
  grid(12, '-ph')
