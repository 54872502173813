/*!
  SECTION PRESS
  ===============================================
  */
.section-press
  padding-bottom: 65px
  @media pad
    padding-bottom: 40px

  .section-title
    margin-bottom: 30px


.press-slider
  .swiper-slide
    padding: 20px 30px
    width: 100%
    max-width: (160px + 60)
    @media wide
      max-width: (220px * 1.7)

.press-slide
  display: block
  height: 206px
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15)
  @media wide
    height: (206px * 2)

  img
    width: 100%
    height: 100%
    object-fit: cover
