/*!
 * --- PAGE 404 ---------------------------------
 */

.page404
  background-color: #000
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  position: relative

  &__bg
    position: absolute
    top: 0
    left: 0
    size(100%,100%)
    bg(center top,cover)
    background-image: url('../images/bg-page404.jpg')
    opacity: 0.6
    z-index: 1

  &__body
    position: relative
    z-index: 2
    color: #fff
    font-size 14px
    line-height: (24/14)
    text-align: center

  &__title
    font-size: 124px
    color: #fff

  &__footer
    margin-top: 40px
