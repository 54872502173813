/*!
  SECTION MAPS
  ===============================================
  */


#maps-slider
  pointer-events: none


.earth-overlay
  pointer-events: all !important
  transform: scale(1)

.globus
  &__picWrap
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    background: #3299ff
    width: 150px
    height: 150px
    left: 50%
    top: 50%
    z-index: 10
    border-radius: 100%
    pointer-events: none !important
    transform: translate(-50%, -50%) scale(0)
    opacity: 0
    transition: all .5s ease

    &_img
      width: 100%
      height: calc(100% - 35px)
      background-size: cover
      background-position: center
      background-repeat: no-repeat

    &_city
      color: #fff
      text-align: center
      font-size: 14px
      padding: 8px
      @media phone
        font-size: 12px

  &__country
    color: #fff
    font-size: 11px
    opacity: .7

  &__baloon
    display: block
    position: absolute
    background: #fff
    color: #1D8EFF
    font-size: 14px
    padding: 10px
    top: 50%
    left: 40px
    width: auto
    white-space: nowrap
    box-shadow: 5px 5px 15px rgba(0,0,0,.15)
    transform: translateY(-50%)
    pointer-events: none !important
    animation: baloon 1s
    animation-delay: 1s
    animation-fill-mode: backwards
    transition: all .5s ease
    z-index: 6
    @media phone
      font-size: 12px
      padding: 9px 10px

    &:before
      display: block
      position: absolute
      content: ''
      left: -10px
      top: calc(50% - 5px)
      width: 0
      height: 0
      border-top: 5px solid transparent
      border-bottom: 5px solid transparent
      border-right: 10px solid #fff

  &__pin
    display: block
    position: relative
    z-index: 5

    &.bubbled
      width: 150px
      height: 150px

      .globus__baloon
        left: 105px

      &:hover
        .globus__baloon
          opacity: 0

        & ~ .globus__picWrap
          opacity: 1
          transform: translate(-50%, -50%) scale(1)

    &:before
      display: block
      position: absolute
      content: ''
      width: 60px
      height: 60px
      top: calc(50% - 30px)
      left: calc(50% - 30px)
      background: #1D8EFF
      border-radius: 100%
      animation: point 3s
      animation-iteration-count: infinite
      animation-delay: 1s
      opacity: .2
      z-index: 1

    &:after
      display: block
      position: absolute
      content: ''
      width: 40px
      height: 40px
      top: calc(50% - 20px)
      left: calc(50% - 20px)
      background: #1D8EFF
      border-radius: 100%
      opacity: .2
      animation: point 2.5s
      animation-iteration-count: infinite
      animation-delay: 1.5s
      z-index: 2

    span
      display: block
      position: absolute
      width: 20px
      height: 20px
      top: calc(50% - 10px)
      left: calc(50% - 10px)
      z-index: 3

      &:before
        display: block
        position: absolute
        content: ''
        width: 20px
        height: 20px
        top: calc(50% - 10px)
        left: calc(50% - 10px)
        background: #1D8EFF
        border-radius: 100%
        animation: point 2s
        opacity: .2
        animation-iteration-count: infinite
        animation-delay: 2s
        z-index: 4

      &:after
        display: block
        position: relative
        content: ''
        width: 6px
        height: 6px
        top: calc(50% - 3px)
        left: calc(50% - 3px)
        background: #ffffff
        border-radius: 100%
        z-index: 5

@keyframes baloon {
  from {
    transform: scale(0) translateY(-50%)
    opacity: 0
  }

  to {
    transform: scale(1) translateY(-50%)
    opacity: 1
  }
}

@keyframes point {
  from {
    transform: scale(0)
    opacity: 0
  }

  15% {
    opacity: .2
  }

  85% {
    opacity: .2
  }

  to {
    transform: scale(1)
    opacity: 0
  }
}

.section-maps
  padding: 100px 0 50px
  @media phone
    padding: 25px 0 20px


.maps-slider
  padding: 180px 0 160px
  position: relative
  @media wide
    padding: (180px * 1.5) 0 (160px * 1.5)
  @media all and (max-width: 1199px)
    padding: 50px 0
  @media pad
    padding: 0
    padding-top: 12px

  &__planet
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 100%
    max-width: 900px
    // max-width: 75%
    @media wide
      max-width: 75%
    @media tablet
      max-width: 50%
    @media pad
      position: relative
      top: auto
      left: auto
      width: 100%
      max-width: 100%
      // max-width: 535px
      height: 535px
      border-radius: 50% 50% 0 0
      margin: 0 auto
      background: linear-gradient(to bottom, rgba(#fff,0) 0, rgba(#fff,0) 50%, colorText 50%, colorText 100%)
      z-index: 3
    @media phone
      height: 335px
      width: auto
      max-width: none
      margin-left: -20px
      margin-right: -20px
      background: linear-gradient(to bottom, rgba(#fff,0) 0, rgba(#fff,0) 50%, colorText 50%, colorText 100%)

    .earth-container
      position: absolute
      top: -140px
      bottom: -140px
      width: 100%
      @media pad
        top: -45px
        bottom: -45px
      @media phone
        top: -55px
        bottom: -55px
        background: linear-gradient(to bottom, rgba(#fff,0) 0, rgba(#fff,0) 50%, colorText 50%, colorText 100%)

  &__box
    col(1,2)
    margin-left: 50%
    position: relative
    z-index: 5
    @media pad
      cl(1,1)
      margin-left: 0
      margin-top: -210px
    @media phone
      margin-top: -165px


.mapsCardSingle
  display: flex
  flex-wrap: wrap
  position: relative
  color: #fff
  padding: 50px 80px 80px 50px
  background: colorText
  transit()
  @media pad
    display: block
    margin-top: 180px
    padding: 30px 24px 50px
  @media phone
    margin-top: 190px
    padding: 0
    padding-top: 14px

  &__box
    display: block
    width: 100%
    max-width: 470px
    @media wide
      max-width: (470px * 1.5)
    @media tablet
      max-width: 100%

  &__buttons
    width: 100%
    margin-bottom: 110px
    @media wide
      margin-bottom: (110px * 2.5)
    @media pad
      margin-bottom: 30px
    @media phone
      padding: 0 24px

    .btn-ui-square-group
      @media phone
        width: 100%

    .btn-ui-square
      &:last-child
        margin-left: auto

  &__items
    @media pad
      padding-right: 200px
    @media phone
      padding: 0 24px 55px

  &__title
    font-family: fontPlayfair
    text(48,56)
    @media pad
      text(30,38)

  &__txt
    text(14,24)
    opacity: 0.5
    padding-top: 18px
    @media pad
      text(14,22)
      max-width: 100%

  &__info
    font-size: 14px
    padding-top: 28px
    @media pad
      padding-top: 18px

    .slash-sep
      @media phone
        margin: 0 10px

  &__pictures
    position: absolute
    overflow: hidden
    top: -50px
    left: 350px
    margin-bottom: 10px
    height: 240px
    width: 172px
    @media wide
      height: (240px * 1.5)
      width: (172px * 1.5)
      left: (350px * 1.4)
    @media laptop
      left: auto
      right: 80px
    @media pad
      top: auto
      bottom: 50px
      right: 24px
      margin-bottom: 0
    @media phone
      position: relative
      bottom: auto
      right: auto
      width: 100%
      height: 240px

  &__picture
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%

    img
      width: 100%
      height: 100%
      object-fit: cover

    &.is-active
      z-index: 2
