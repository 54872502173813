/*!
  HELPERS
  ===============================================
  */
.link
  transit()

  &:hover
    opacity: 0.6


.hide
  display: none


.icon
  display: block


.img
  display: block
  max-width: 100%

picture
  display: block
  img
    width: 100%


.tab
  display: none
  visibility: hidden
  opacity: 0

  &.is-active
    display: block
    visibility: visible
    animation: tab-show 1s linear 0.01s 1 forwards

@keyframes tab-show
  from
    opacity: 0
  to
    opacity: 1


.iframe-container
  overflow: hidden
  padding-top: 56.25%
  position: relative
  width: 100%

  iframe
    border: 0
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%


.is-process
  position: relative

  &:before, &:after
    pseudo()
    z-index: 4

  &:before
    width: 100%
    top: 0
    bottom: 0
    left: 0
    background-color: rgba(#fff, 0.8)

  &:after
    top: 50%
    left: 50%
    size(30px,30px)
    border-radius: 50%
    border-style: solid
    border-width: 3px
    border-color: colorText rgba(#fff,0) colorText rgba(#fff,0)
    margin-top: -15px
    margin-left: -15px
    z-index: 5
    animation: process-rotate 1s ease-in-out infinite forwards

@keyframes process-rotate
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)


.js-split-text
  .char, .word
    display: inline-block

  .char-space
    display: inline-block
    width: 0.25em

#baguetteBox-overlay .full-image img
  max-width: 1000px
  max-height: 84%
  box-shadow: none

.baguetteBox-button
  background-color: rgba(#fff,0)
  &:hover, &:focus
    background-color: rgba(#fff,0)

  svg
    polyline
      stroke: rgba(colorText,0.3)
      transit()

  &:hover svg polyline
    stroke: colorBlue

.baguetteBox-button#close-button
  bg(center,contain)
  background-image: url('../icons/close.svg')
  size(23px,23px)
  top: 34px
  right: 52px

  svg
    display: none


.odometer.odometer-auto-theme,
.odometer.odometer-theme-default
  font-family: inherit
  line-height: 1