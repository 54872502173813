/*!
  SECTION MAP
  ===============================================
  */
.section-map
  margin-bottom: 70px
  position: relative
  z-index: 2


.map-box
  background-color: #ccc
  width: 100%
  height: 640px
  position: relative
  @media phone
    height: 420px

  &__container
    size(100%,100%)
    position: absolute
    top: 0
    left: 0

  &__controls
    position: absolute
    top: 50%
    right: 40px
    transform: translateY(-50%)
    display: flex
    flex-direction: column
    @media phone
      top: 10px
      right: 10px
      transform: none

  &__control
    display: block
    size(40px,40px)
    background-color: colorText
    color: #fff
    position: relative
    font-family: fontPlayfair
    line-height: 40px
    font-size: 27px

    &:first-child
      margin-bottom: 20px
      @media phone
        margin-bottom: 10px

    &:before,
    &:after
      pseudo()
      background-color: #fff
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)

    &.is-zoom-in
      &:before
        size(14px,1px)
      &:after
        size(1px,14px)

    &.is-zoom-out
      &:before
        size(14px,1px)
      &:after
        display: none

    &:hover
      background-color: colorSand


.map-box-popup
  .gm-style-iw-c
    background: none
    color: colorText
    font-size: 14px
    padding: 0 !important
    border-radius: 0
    box-shadow: none

  .gm-style-iw-d
    overflow: hidden !important

  .gm-ui-hover-effect
    display: none !important

  &::after
    display: none
    // background: linear-gradient(45deg,colorBlue 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%) !important
    // box-shadow: -2px 2px 2px 0 rgba(colorBlue,.4) !important

  &__txt
    font-family: fontNoto
    font-size: 14px


.map-box-popup-length
  .gm-style-iw-c
    background: none
    padding: 0 !important
    box-shadow: none
    font-size 12px
    color: rgba(colorText,0.5)
    text-align: center

  .gm-style-iw-d
    overflow: hidden !important

  .gm-ui-hover-effect
    display: none !important

  &::after
    display: none
    // background: linear-gradient(45deg,rgba(#fff,0.5) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%) !important
    // box-shadow: -2px 2px 2px 0 rgba(#fff,.1) !important

  &__txt
    font-family: fontNoto
    font-size: 12px
    color: rgba(colorText,0.5)
