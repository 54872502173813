/*!
  BLOCK SEARCH
  ===============================================
  */
.header-search
  overflow-y: auto


.h-search-form

  &__group
    position: relative

  &__input
    font-family: fontPlayfair
    text(48,74)
    padding-right: 32px
    padding-bottom: 20px
    border-bottom: 1px solid #EDEDED
    &.error
      border-bottom-color: #fa5252

    @media pad
      text(30,50)
      padding-bottom: 5px

  &__button
    position: absolute
    height: 100%
    width: 18px
    top: 0
    right: 0

    .icon
      size(18px,18px)
      fill: colorText


.h-search-results
  padding-top: 50px

  .swiper-slide
    max-width: (300px + 50)
    padding-right: 50px
    @media phone
      max-width: (260px + 20)
      padding-right: 20px
