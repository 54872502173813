/*!
  UI
  ===============================================
  */
.link-ui
  &:hover
    color: colorBlue


.link-ui-color
  color: colorBlue


.cursor-ui
  size(100px,100px)
  border-radius: 100%
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  top: -50px
  left: -50px
  z-index: 9999999999
  pointer-events: none
  opacity: 0

  &.is-darken
    mix-blend-mode: darken

  &__inner
    position: absolute
    top: 50%
    left: 50%
    size(20px,20px)
    transform: translate(-50%,-50%)
    display: flex
    align-items: center
    justify-content: center
    background-color: rgba(colorBlue, 0)
    border-radius: 100%
    font-size: 12px
    color: #fff
    transit(0.15s)

    span
      transform: scale(0)
      transition: all 0.3s ease-in

  &__outer
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%) scale(1)
    background-color: rgba(colorBlue, 0.9)
    size(20px,20px)
    border-radius: 100%
    transition: all 0.15s ease-in

  &.is-hover &__inner
    background-color: rgba(colorBlue, 0)
  &.is-hover &__outer
    transform: translate(-50%,-50%) scale(2)

  &.is-hover-more &__inner
    size(120px,120px)
    background-color: rgba(colorBlue, .9)

    span
      transform: scale(1)

  &.is-hover-more &__outer
    transform: translate(-50%,-50%) scale(0)

  &.is-disabled &__inner
    background-color: rgba(#ccc,0.9)

  &.is-disabled &__outer
    background-color: rgba(#ccc,0.25)
    transform: translate(-50%,-50%) scale(0.65)



/*! --- TITLES ------------------------- */
.section-title
  text-align: center
  margin: 0 auto 60px
  @media pad
    margin-bottom: 40px
  @media phone
    margin-bottom: 30px

  &__bottom
    max-width: 500px
    margin: 20px auto 0
    text(14,24)
    color: rgba(colorText,0.5)
    @media phone
      line-height: (22/14)
      margin-top: 13px

  &--light
    color: #fff

  &--light &__bottom
    color: rgba(#fff,0.5)


.title-ui
  font-family: fontPlayfair
  font-size: 48px
  color: colorText
  font-weight: normal
  @media pad
    font-size: 30px

  &--light
    color: #fff


.page-title-ui
  font-family: fontPlayfair
  text(64,74)
  color: colorText
  font-weight: normal
  @media pad
    font-size: 40px
  @media phone
    font-size: 34px


.page-head
  position: relative
  // overflow: hidden
  margin-top: -30px
  @media pad
    display: flex
  @media phone
    margin-top: 0
    flex-direction: column-reverse

  &.is-pattern
    &:before
      pseudo()
      height: 100%
      width: 60%
      left: 0
      bottom: -135px
      bg(bottom left, contain)
      background-image: url('../images/page-head-pattern.jpg')
      z-index: -1
      @media phone
        width: 100%
        bottom: -60px

  &__left
    width: 100%
    height: 700px
    display: flex
    align-items: center
    @media wide
      height: (700px * 1.5)
    @media tablet
      height: 600px
    @media pad
      height: auto
      max-width: 60%
    @media phone
      max-width: 100%

  &__right
    position: absolute
    width: 100%
    max-width: 50%
    right: 0
    top: 0
    height: 100%
    @media pad
      position: relative
      right: auto
      top: auto
      height: auto
      max-width: 40%
    @media phone
      max-width: 100%
      margin-bottom: 30px

    .swiper-container
      size(100%,100%)
      @media phone
        height: 420px

  &__picture
    height: 100%

    img
      height: 100%
      object-fit: cover

    &--gray img
      filter: grayscale(100%)
      transit()
      // &:hover
      //   filter: grayscale(0)

  &__box
    max-width: 50%
    padding-right: 4%
    @media pad
      max-width: 100%
      padding: 50px 0
    @media phone
      padding: 0

  &__title
    margin-bottom: 30px

  &__txt
    color: rgba(colorText,0.5)
    text(14,24)

    p:not(:last-child)
      margin-bottom: 1.7em

    strong
      font-family: fontPlayfair
      text(27,35)
      color: colorText
      font-weight: normal
      @media pad
        text(20,28)

  &__link
    margin-top: 40px


.animation-overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: #fff
  z-index: 5
  transform-origin: bottom


.single-title
  position: relative
  z-index: 1

  &__info
    font-size: 14px
    margin-bottom: 20px

    &--light
      color: rgba(colorText,0.5)

  &--push-down
    margin-bottom: 30px


/*! --- SLIDER ------------------------- */
.slider-right-side
  position: relative

  &__left
    width: 100%
    max-width: 1140px
    padding: 0 20px
    position: absolute
    top: -12px
    bottom: 0
    left: 50%
    transform: translateX(-50%)
    display: flex
    flex-direction: column
    justify-content: space-between
    @media phone
      display: none

  &__right
    max-width: 66%
    margin-left: auto
    position: relative
    z-index: 1
    @media note
      max-width: 70%
    @media laptop
      max-width: 75%
    @media phone
      margin-left: 20px
      max-width: 100%

  .swiper-slide
    max-width: (300px + 50)
    padding-right: 50px
    @media wide
      max-width: (350px * 1.8)
    @media phone
      padding-right: 20px
      max-width: (260px + 20)


.slider-pagination
  font-family: fontPlayfair
  font-size: 27px
  display: inline-flex
  align-items: flex-start
  padding-top: 4px

  .is-current
    display: inline-block
    font-size: 64px
    line-height: 1
    position: relative
    top: -13px
    padding-right: 8px

  .is-total
    line-height: 1
    position: relative
    top: -1px
    padding-left: 4px


.slider-centered
  position: relative
  z-index: 2

  .swiper-slide
    max-width: ((600 * 100%) / 1200)
    height: 420px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    transform: scale((400/600))
    transit()
    @media phone
      transform: scale(1)
      max-width: 88%
      height: 230px
      padding: 0 10px

    &.is-prev
      transform-origin: 75% 50%

    &.is-next
      transform-origin: 25% 50%

    &.is-active
      transform: scale(1)

  .slide-picture
    img
      width: 100%
      height: 100%
      object-fit: cover

    &__button
      display: block
      width: 100%

  .slider-nav
    width: 22%


.slider-dots
  display: flex
  align-items: center
  justify-content: center

  &__item
    size(8px,8px)
    border: 1px solid rgba(#fff,0.4)
    border-radius: 50%
    transit()

    &:not(:last-child)
      margin-right: 12px

    &.is-active
      background-color: colorSand


.slider-nav
  position: absolute
  top: 0
  height: 100%
  width: 40%
  background: rgba(#fff,0)
  z-index: 5

  &.prev
    left: 0

  &.next
    right: 0


/*! --- FORMS -------------------------- */
.button, .input
  display: block
  width: 100%


.fg
  position: relative

  .error-message
    position: absolute
    top: 100%
    left: 0
    width: 100%
    color: #fa5252
    font-size: 0.8em
    margin-top: 2px


.btn-ui
  display: inline-flex
  align-items: center
  justify-content: center
  height: 54px
  width: 100%
  max-width: 200px
  font-family: fontNoto
  background-color: colorText
  color: #fff
  font-size: 14px
  position: relative
  overflow: hidden

  &:after
    pseudo()
    size(30px,30px)
    bottom: 0
    right: 0
    background-color: colorSand
    transform: translate(50%,50%) rotate(45deg)
    transit(0.6s)

  &:hover:after
    transform: translate(50%,50%) rotate(45deg) scale(16)
    // border-radius: 50%

  span
    position: relative
    z-index: 1

  &--bordered-light
    border: 1px solid rgba(#FFF2F2,0.3)
    background: none


.btn-ui-square
  display: flex
  align-items: center
  justify-content: center
  size(40px,40px)
  border: 1px solid rgba(#fff,0.2)

  .icon
    fill: #fff
    position: relative
    z-index: 1

  &:hover
    border-color: #fff

  &--stroke
    position: relative

    &:hover
      border-color: rgba(#fff,0.2)

  .icon-border
    position: absolute
    top: -1px
    left: -1px
    width: 40px
    height: 40px
    fill: rgba(#fff,0)

    rect
      width: 100%
      height: 100%
      stroke: #fff
      stroke-width: 1
      transit()

    &--top-right rect
      stroke-dasharray: 20,(30+40+40)
      stroke-dashoffset: -30

    &--bottom-left rect
      stroke-dasharray: 20,(30+40+40)
      stroke-dashoffset: -110

    &--bottom-left-dot rect
      stroke-dasharray: 0, 112
      stroke-dashoffset: -120

  &:hover .icon-border--top-right rect
    stroke-dasharray: (40*4),0

  &:hover .icon-border--bottom-left rect,
  &:hover .icon-border--bottom-left-dot rect
    stroke-dasharray: (40*4),0

  .icon-social
    size(16px,16px)

  .icon-slider
    size(14px,14px)

  .icon-weather
    size(20px,15px)

  &.is-disabled
    opacity: 0.25

  &--dark
    border-color: rgba(colorText,0.2)

    .icon
      fill: colorText

    &:hover .icon-border rect
      stroke: colorText


.btn-ui-square-group
  display: inline-flex
  align-items: center

  .btn-ui-square
    &:not(:last-child)
      margin-right: 20px


.input-ui
  display: block
  width: 100%
  height: 50px
  padding-bottom: 10px
  border-bottom: 1px solid #EDEDED
  font-family: fontPlayfair
  text(27,34)
  @media phone
    text(20,27)

  &.error
    border-bottom-color: #fa5252


.label-ui
  display: block
  font-family: fontPlayfair
  text(27,34)
  @media phone
    text(20,27)


.fg-labeled
  position: relative

  .input-ui
    position: relative
    z-index: 2

  .label-ui
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    z-index: 1
    transit()

  .input-ui--ta ~ .label-ui
    top: 3px
    transform: none

  &.is-focus .label-ui,
  .input-ui:focus ~ .label-ui
    font-family: fontNoto
    text(14,24)
    top: 0
    transform: none


/*! --- PAGE --------------------------- */
.page-wrapper
  padding: 25px 0 80px
  @media phone
    padding: 0 0 30px


.section
  &:not(:last-child)
    margin-bottom: 60px

  &-w-separator
    position: relative
    padding-bottom: 80px
    @media pad
      padding-bottom: 40px

    &:after
      pseudo()
      width: 60px
      height: 1px
      background-color: colorSand
      bottom: 0
      left: 50%
      margin-left: -30px


.head-navigator
  font-size: 14px
  text(14,32)
  display: flex
  flex-wrap: wrap
  align-items: center
  margin-top: 25px
  margin-bottom: 35px
  @media phone
    margin-top: 15px
    margin-bottom: 30px

  &__item
    border-bottom: 1px solid rgba(#fff,0)


    &:not(:last-child)
      margin-right: 24px

    &:not([href]),
    &:not([type="button"])
      color: rgba(colorText,0.5)

    &.is-current
      color: colorText
      border-bottom: 1px solid colorSand

    &[href],
    &[type="button"]
      color: colorText
      line-height: inherit

      &:hover
        color: colorBlue

    i
      font-style: normal
      color: rgba(colorText,0.5)


.btn-loader
  display: flex
  align-items: center
  justify-content: center
  size(120px,120px)
  margin: 0 auto
  background-color: colorBlue
  border-radius: 100%
  margin-top: 80px
  @media phone
    margin-top: 30px
    size(80px,80px)

  &:hover
    background-color: colorText

  span
    display: block
    size(6px,6px)
    border-radius: 100%
    border: 1px solid #fff

    &:not(:last-child)
      margin-right: 8px

  &.is-active span
    &:first-child
      // animation: btn-loader-move 0.8s ease-in-out infinite alternate forwards
      animation: btn-loader-dots 0.8s ease-in-out infinite alternate forwards

    &:nth-child(2)
      // animation: btn-loader-move 0.8s ease-in-out 0.2s infinite alternate forwards
      animation: btn-loader-dots 0.8s ease-in-out 0.5s infinite alternate forwards

    &:last-child
      // animation: btn-loader-move 0.8s ease-in-out 0.3s infinite alternate forwards
      animation: btn-loader-dots 0.8s ease-in-out 1s infinite alternate forwards


@keyframes btn-loader-move
  0%
    transform: translateY(0)
  50%
    transform: translateY(100%)
  100%
    transform: translateY(-100%)

@keyframes btn-loader-dots
  from
    opacity: 0
  to
    opacity: 1



.share-ui
  position: relative
  padding-top: 40px
  margin-top: 60px

  &:before
    pseudo()
    top: 0
    left: 0
    background-color: colorSand
    width: 60px
    height: 1px

  &__title
    font-family: fontPlayfair
    text(27,35)
    margin-bottom: 25px


.hero-video
  display: flex
  align-items: center
  justify-content: center
  height: 600px
  position: relative
  @media wide
    height: (600px * 1.5)
  @media pad
    height: auto

  img
    height: 100%
    width: 100%
    object-fit: cover

  .play-video-icon
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    z-index: 1


.play-video-icon
  display: block
  size(142px,142px)
  @media phone
    size(70px,70px)

  &__el
    size(100%,100%)

  &__bg
    fill: colorSand
    transit()

  &__arrow
    fill: #fff

  &:hover &__bg
    fill: colorText


.play-video-button
  position: relative

  .play-video-icon
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    z-index: 1


.section-picture
  padding: 60px 0
  position: relative
  margin-bottom: 60px
  @media phone
    padding: 30px 0

  &:before
    pseudo()
    width: 38%
    height: 100%
    left: 0
    top: 0
    background-color: colorText

  &__pic
    display: block
    width: 100%
    height: 620px
    position: relative
    z-index: 1
    @media wide
      height: (620px * 1.5)
    @media pad
      height: auto

    .play-video-icon
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      z-index: 2

  img
    width: 100%
    height: 100%
    object-fit: cover


.line-slash-sep
  display: flex
  align-items: center

  .slash-sep
    display: inline-block
    width: 1px
    height: 23px
    background-color: #fff
    transform-origin: 50% 50%
    transform: rotate(30deg)
    opacity: 0.3
    margin: 0 20px


.tabs-ui
  display: flex
  justify-content: center
  flex-wrap: wrap
  @media pad
    justify-content: flex-start

  &__item
    width: auto
    text(14,24)
    padding-bottom: 5px
    border-bottom: 1px solid rgba(#fff,0)
    margin: 0 20px 10px
    @media pad
      padding-bottom: 0
    @media phone
      margin-left: 10px
      margin-right: 10px

    &:hover
      color: colorBlue

    &.is-active
      color: colorSand
      border-bottom: 1px solid colorSand


.tab-ui
  padding-top: 20px

  &__title
    font-family: fontPlayfair
    text(27,35)
    margin-bottom: 37px
    @media phone
      font-size: 20px
      margin-bottom: 20px

  &__cols
    margin: 0 -50px
    @media pad
      margin: 0 -25px

    &--middle
      align-items: center

    &--picture
      @media phone
        flex-direction: column-reverse

      .tab-ui__picture
        @media phone
          margin-bottom: 30px

  &__col
    padding: 0 50px
    @media pad
      padding: 0 25px

  &__txt
    padding-bottom: 45px

    &--light
      text(14,24)
      color: rgba(colorText,0.5)

  &__lines
    font-size: 14px
    line-height: 1

    p
      display: flex

      &:not(:last-child)
        margin-bottom: 1.2em

      i
        display: block
        flex-grow: 1
        border-bottom: 1px dashed rgba(#E9E9E9,0.2)
        margin: 0 10px

    &--dark p i
      border-bottom-color: rgba(#E9E9E9,0.8)

  &__picture
    size(100%,100%)

    img
      height: 100%
      width: 100%
      object-fit: cover
      margin-left: auto
      @media phone
        height: 100%

  &__slider
    size(376px,540px)
    margin-right: 0
    margin-left: auto
    @media phone
      width: 292px
      height: 420px
      margin-right: auto
      margin-bottom: 30px


.spec-list-ui
  text(14,20)

  &__item:not(:last-child)
    @media phone
      margin-bottom: 50px

  p:not(:last-child)
    margin-bottom: 1.5em

  ul
    resetul()
    list-style: disc
    padding-left: 1.2em
    color: rgba(colorText,0.5)

    li:not(:last-child)
      margin-bottom: 1em

  &--light ul
    color: rgba(#fff,0.5)


.text
  text(14,20)

  p, ul, ol
    &:not(:last-child)
      margin-bottom: 1.1em

  ul, ol
    resetul()

    li:not(:last-child)
      margin-bottom: 1.1em

  ul
    li
      position: relative
      padding-left: 1.3em

      &:before
        pseudo()
        top: 0.55em
        left: 0
        size(4px,4px)
        background-color: colorSand
        border-radius: 100%
        transit()

  ol
    counter-reset: olCounter

    li
      position: relative

      &:before
        counter-increment: olCounter
        content: counter(olCounter) "."
        margin-right: 0.5em
        display: inline-block
        vertical-align: middle
        color: colorSand

  h1, h2, h3, h4, h5, h6
    font-family: fontPlayfair
    color: colorText
    text(27,35)
    @media phone
      font-size: 20px

    &:not(:last-child)
      margin-bottom: 15px


.ps
  position: relative
  overflow: hidden
  overflow-anchor: none
  -ms-overflow-style: none
  touch-action: auto
  -ms-touch-action: auto
  padding-right: 30px

  &__rail-y
    position: absolute
    right: 0
    transition: background-color .2s linear, opacity .2s linear
    background-color: lightness(#aaa,95%)
    border-radius: 5px
    width: 5px

  &__thumb-y
    position: absolute
    background-color: #aaa
    border-radius: 5px
    width: 100%
    right: 0
    transition: background-color .2s linear, height .2s ease-in-out
