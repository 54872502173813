/*!
  SECTION LOADER
  ===============================================
  */
#loader
  display: block
  position: relative
  z-index: 1000
  opacity: 1
  visibility: visible

  &.is-done
    opacity: 0
    visibility: hidden
    transit(0.6s)
    z-index: -1


.section-loader
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1000
  background-color: #0B1125
  color: #fff
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  bg(center,cover)
  background-image: url('../images/loader.jpg')

  &__body
    width: 100%
    text-align: center

  &__compas
    position: relative
    height: 180px
    width: 180px
    margin: 0 auto 30px

    &:before
      pseudo()
      width: 100%
      height: 100%
      top: 0
      left: 0
      bg(center,contain)
      background-image: url('../images/loader-compas.svg')
      animation: loader-compas-rotate 10s linear infinite forwards

  &__header
    font-family: fontPlayfair
    margin-bottom: 15px

  &__title
    text-transform: uppercase
    text(27,32)
    margin-bottom: 5px
    opacity: 0
    animation: loader-fade-up 0.6s ease-in 0.1s 1 forwards

  &__subtitle
    text(18,32)
    opacity: 0
    animation: loader-fade-up 0.6s ease-in 0.2s 1 forwards

  &__counter
    opacity: 0
    animation: loader-fade-up 0.6s ease-in 0.3s 1 forwards

@keyframes loader-compas-rotate
  0%
    transform: rotate(0deg)
  5%
    transform: rotate(10deg)
  25%
    transform: rotate(-15deg)
  50%
    transform: rotate(10deg)
  75%
    transform: rotate(-5deg)
  100%
    transform: rotate(0deg)


@keyframes loader-fade-up
  from
    transform: translateY(30px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1
