/*!
  BLOCK HEADER FORM
  ===============================================
  */
.header-form-modal
  z-index: 20
  padding-top: 0

  &__bottom
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    overflow-y: auto


.header-form
  overflow-y: auto
  position: relative

  &__body
    width: 100%
    max-width: 523px
    margin: 0 auto

  &__group
    padding-top: 25px

    &:not(:last-child)
      margin-bottom: 15px

    .error-message
      text-align: right

  &__input-ta
    height: 220px
    @media phone
      height: 160px

  &__sent
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: #fff
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    opacity: 0
    visibility: hidden
    z-index: -1
    font-family: fontPlayfair
    text(48,54)
    text-align: center
    @media phone
      text(28,34)

    .button
      margin-top: 45px

  &.is-sent &__sent
    opacity: 1
    visibility: visible
    z-index: 2
